import React from "react";

function Urunler() {
  return (
    <div className="container">
      <hr className="tall" />
      <h2 className="font-weight-bold text-color-dark">Ürünler</h2>
      {/* Ürünler - 1 */}
      <div className="row mt-lg-5">
        <div
          className="wow col-lg-3 appear-animation"
          data-appear-animation="rotateInDownLeft"
          data-appear-animation-delay="600"
        >
          <span className="wow thumb-info thumb-info-hide-wrapper-bg">
            <span className="thumb-info-wrapper">
              <a href="/faaliyetler#urunler">
                <img
                  src="img/urunler/ates-bacasi/A-B-001.jpg"
                  className="img-fluid"
                  alt="Ates Bacasi"
                />
              </a>
              <span className="thumb-info-title">
                <span className="thumb-info-inner">#AB-001</span>
                <span className="thumb-info-type">Ateş Bacası</span>
              </span>
            </span>
          </span>
        </div>

        <div className="col-lg-3">
          <span
            className="wow thumb-info thumb-info-hide-wrapper-bg img-fluid appear-animation"
            data-appear-animation="slideInDown"
            data-appear-animation-delay="300"
          >
            <span className="thumb-info-wrapper">
              <a href="/faaliyetler#urunler">
                <img
                  src="img/urunler/ates-bacasi/A-B-003.jpg"
                  className="img-fluid"
                  alt="Ates Bacasi"
                />
              </a>
              <span className="thumb-info-title">
                <span className="thumb-info-inner">#AB-003</span>
                <span className="thumb-info-type">Ateş Bacası</span>
              </span>
            </span>
          </span>
        </div>

        <div className="col-lg-3">
          <span
            className="wow thumb-info thumb-info-hide-wrapper-bg img-fluid appear-animation"
            data-appear-animation="slideInDown"
            data-appear-animation-delay="400"
          >
            <span className="thumb-info-wrapper">
              <a href="/faaliyetler#urunler">
                <img
                  src="img/urunler/ates-cukuru-bahce-somine/B-S-001.jpg"
                  className="img-fluid"
                  alt="Bahce Sominesi"
                />
              </a>
              <span className="thumb-info-title">
                <span className="thumb-info-inner">#BS-001</span>
                <span className="thumb-info-type">Bahçe Şöminesi</span>
              </span>
            </span>
          </span>
        </div>

        <div className="col-lg-3">
          <span
            className="wow thumb-info thumb-info-hide-wrapper-bg img-fluid appear-animation"
            data-appear-animation="rotateInDownRight"
            data-appear-animation-delay="600"
          >
            <span className="thumb-info-wrapper">
              <a href="/faaliyetler#urunler">
                <img
                  src="img/urunler/ates-cukuru-bahce-somine/A-C-003.jpg"
                  className="img-fluid"
                  alt="Ates Cukuru"
                />
              </a>
              <span className="thumb-info-title">
                <span className="thumb-info-inner">#AC-003</span>
                <span className="thumb-info-type">Ateş Çukuru</span>
              </span>
            </span>
          </span>
        </div>
      </div>

      {/* Ürünler - 2 */}
      <div className="row mt-lg-5">
        <div
          className="wow col-lg-3 appear-animation"
          data-appear-animation="rotateInDownLeft"
          data-appear-animation-delay="600"
        >
          <span className="wow thumb-info thumb-info-hide-wrapper-bg">
            <span className="thumb-info-wrapper">
              <a href="/faaliyetler#urunler">
                <img
                  src="img/urunler/corten-heykel-yapi/C-H-Y-002.jpg"
                  className="img-fluid"
                  alt="Corten Sac"
                />
              </a>
              <span className="thumb-info-title">
                <span className="thumb-info-inner">#CHY-002</span>
                <span className="thumb-info-type">Corten Heykel Yapı</span>
              </span>
            </span>
          </span>
        </div>

        <div className="col-lg-3">
          <span
            className="wow thumb-info thumb-info-hide-wrapper-bg img-fluid appear-animation"
            data-appear-animation="slideInUp"
            data-appear-animation-delay="300"
          >
            <span className="thumb-info-wrapper">
              <a href="/faaliyetler#urunler">
                <img
                  src="img/urunler/masa-tabure/M-S-001-3.jpg"
                  className="img-fluid"
                  alt="Masa Tabure"
                />
              </a>
              <span className="thumb-info-title">
                <span className="thumb-info-inner">#MS-001</span>
                <span className="thumb-info-type">Masa&Tabure</span>
              </span>
            </span>
          </span>
        </div>

        <div className="col-lg-3">
          <span
            className="wow thumb-info thumb-info-hide-wrapper-bg img-fluid appear-animation"
            data-appear-animation="slideInUp"
            data-appear-animation-delay="400"
          >
            <span className="thumb-info-wrapper">
              <a href="/faaliyetler#urunler">
                <img
                  src="img/urunler/duvar-aksesuar/D-A-009.jpg"
                  className="img-fluid"
                  alt="Metal Duvar Aksesuarı"
                />
              </a>
              <span className="thumb-info-title">
                <span className="thumb-info-inner">#DA-009</span>
                <span className="thumb-info-type">Duvar Aksesuarı</span>
              </span>
            </span>
          </span>
        </div>

        <div className="col-lg-3">
          <span
            className="wow thumb-info thumb-info-hide-wrapper-bg img-fluid appear-animation"
            data-appear-animation="rotateInDownRight"
            data-appear-animation-delay="600"
          >
            <span className="thumb-info-wrapper">
              <a href="/faaliyetler#urunler">
                <img
                  src="img/urunler/mumluk/M-001.jpg"
                  className="img-fluid"
                  alt="Mumluk"
                />
              </a>
              <span className="thumb-info-title">
                <span className="thumb-info-inner">#M-001</span>
                <span className="thumb-info-type">Mumluk</span>
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Urunler;
