import React from "react";

function RefLogo() {
  return (
    <div className="container">
      <hr className="tall" />

      {/* Referans Logoları */}
      <div
        className="wow row text-center appear-animation"
        data-appear-animation="zoomIn"
        data-appear-animation-delay="0"
        data-appear-animation-duration="2s"
      >
        <div
          className="owl-carousel owl-theme"
          data-plugin-options='{"responsive": {"0": {"items": 2}, "479": {"items": 2}, "768": {"items": 3}, "979": {"items": 4}, "1199": {"items": 6}}, "autoplay": true, "autoplayTimeout": 3000}'
        >
          <div>
            <img
              className="img-fluid"
              src="img/referans-logo/1.png"
              alt="BilgiliHolding"
            />
          </div>
          <div>
            <img
              className="img-fluid"
              src="img/referans-logo/2.png"
              alt="ToksozGroup"
            />
          </div>
          <div>
            <img
              className="img-fluid"
              src="img/referans-logo/3.png"
              alt="DogusGrubu"
            />
          </div>
          <div>
            <img
              className="img-fluid"
              src="img/referans-logo/4.png"
              alt="Balparmak"
            />
          </div>
          <div>
            <img
              className="img-fluid"
              src="img/referans-logo/5.png"
              alt="MercanMimarlik"
            />
          </div>
          <div>
            <img
              className="img-fluid"
              src="img/referans-logo/6.png"
              alt="EczacıbasiBaxer"
            />
          </div>
          <div>
            <img
              className="img-fluid"
              src="img/referans-logo/7.png"
              alt="YoncaOnuk"
            />
          </div>
          <div>
            <img
              className="img-fluid"
              src="img/referans-logo/8.png"
              alt="IsikPeyzaj"
            />
          </div>
          <div>
            <img
              className="img-fluid"
              src="img/referans-logo/9.png"
              alt="DayGroup"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefLogo;
