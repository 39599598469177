import React from "react";

function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="footer-ribbon ">
            <span>Bizi Takip Edin</span>
          </div>
          <div className="col-lg-4">
            <div className="newsletter">
              <a href="/" className="logo">
                <img
                  alt="Acar Metal Tasarım"
                  className="img-fluid"
                  height="100"
                  width="200"
                  src="img/logo/acar-metal-tasarim-logo.png"
                  title="Acar Metal Tasarım"
                />
              </a>
              <p className="mt-3">
                Acar Metal Tasarım ile ilgili güncel haberler için bizi sosyal
                medya hesaplarımızdan takip edin.
              </p>
            </div>
          </div>

          <div className="col-lg-3">
            <h4>Menü</h4>
            <ul className="list list-icons list-icons-sm">
              <li>
                <i className="fas fa-caret-right"></i> <a href="/">Anasayfa</a>
              </li>
              <li>
                <i className="fas fa-caret-right"></i>{" "}
                <a href="/kurumsal">Kurumsal</a>
              </li>
              <li>
                <i className="fas fa-caret-right"></i>{" "}
                <a href="/faaliyetler">Faaliyetler</a>
              </li>
              <li>
                <i className="fas fa-caret-right"></i>{" "}
                <a href="/faaliyetler#hizmetler">Hizmetler</a>
              </li>
              <li>
                <i className="fas fa-caret-right"></i>{" "}
                <a href="/faaliyetler#urunler">Ürünler</a>
              </li>
              <li>
                <i className="fas fa-caret-right"></i>{" "}
                <a href="/referans">Referans</a>
              </li>
              <li>
                <i className="fas fa-caret-right"></i>{" "}
                <a href="/iletisim">İletişim</a>
              </li>
            </ul>
          </div>

          <div className="col-lg-3">
            <div className="contact-details">
              <h4>İletişim</h4>

              <span className="phone">(216) 304-2644</span>

              <ul className="contact">
                <li>
                  <p>
                    <i className="fas fa-map-marker-alt"></i>
                    <strong>Adres:</strong> Tepeören Mah, Ekşioğlu Erdem Sanayi
                    Sitesi, A-4 Blok No 6-7-8 <strong> Orhanlı / Tuzla</strong>
                  </p>
                </li>

                <li>
                  <p>
                    <i className="fas fa-phone"></i> <strong>Telefon:</strong> 0
                    (216) 304-2644
                  </p>
                </li>
                <li>
                  <i className="far fa-envelope"></i> <strong>Email:</strong>{" "}
                  <a href="mailto:info@acarmetaltasarim.com">
                    info@acarmetaltasarim.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2">
            <h4>Sosyal Medya</h4>
            <ul className="mb-2 social-icons">
              <li className="social-icons-facebook">
                <a
                  href="http://www.facebook.com/acarmetaltasarim"
                  target="_blank"
                  rel="noreferrer"
                  title="Facebook"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li className="social-icons-twitter">
                <a
                  href="http://www.twitter.com/acarmetaltasarm"
                  target="_blank"
                  rel="noreferrer"
                  title="Twitter"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li className="social-icons-linkedin">
                <a
                  href="https://www.linkedin.com/company/acarmetaltasarim"
                  target="_blank"
                  rel="noreferrer"
                  title="Linkedin"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
            <ul className="social-icons ">
              <li className="social-icons-instagram">
                <a
                  href="http://www.instagram.com/acarmetaltasarim"
                  target="_blank"
                  rel="noreferrer"
                  title="İnstagram"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li className="social-icons-youtube">
                <a
                  href="https://www.youtube.com/channel/UCLjQyXVLqlwINAP5XbN8yLA"
                  target="_blank"
                  rel="noreferrer"
                  title="YouTube"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li className="social-icons-pinterest">
                <a
                  href="https://tr.pinterest.com/acarmetaltasarim"
                  target="_blank"
                  rel="noreferrer"
                  title="Pinterest"
                >
                  <i className="fab fa-pinterest"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <p className="text-center">
                © Copyright 2021. Tüm Hakları Saklıdır.
              </p>
            </div>
            <div className="col-lg-3">
              <p className="text-right">
                Design:{" "}
                <a
                  href="http://www.cagriacar.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="cagriacar"
                >
                  cagriacar
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
