import React from "react";
import Firma from "./Firma";
function RefFirma() {
  return (
    <div className="container">
      {/* 1.Sıra */}
      <div className="row">
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/paket-postanesi.jpg"}
            imgLink2={"img/referanslar/paket-postanesi-2.jpg"}
            refBaslik={"Restorasyon"}
            refAciklama={
              "SBO Yatcılık firmasının önderliğinde Acar Metal Tasarım firması olarak 1890 yıllarında yapılmış olan paket postanesinin resterasyon projesi uygulanmıştır."
            }
            refProjeAdi={"Galataport - Tarihi Paket Postanesi"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Doğuş Grubu - Bilgili Holding "}
            refFirmaAciklama={
              "Ana firma yüklenicilerine ait proje 2017 yılında başlanmıştır."
            }
            refYer={"Beyoğlu / İSTANBUL"}
            refYerAciklama={"2020 yılında proje tamamlanmıştır."}
          />
        </div>
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/vk108.jpg"}
            imgLink2={"img/referanslar/vk108-2.jpg"}
            refBaslik={"Çelik Günlendirme"}
            refAciklama={
              "VK108 yaklaşık 5.000 metrekare üzerine inşa edilmektedir.Bir restorasyon projesi olan VK108 aynı zamanda Türkiye'de en büyük bina güçlendirme projelerinden de biridir. Güçlendirme tadilat işlemlerini SBO Yatcılık ortaklığı ile günlendirme projesi uygulanmıştır."
            }
            refProjeAdi={"VK108"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Bilgili Holding / Valikonağı Gayrimenkul A.Ş."}
            refFirmaAciklama={
              "firmasına 2016 tarihinde proje  uygulaması gerçekleştirilmiştir."
            }
            refYer={"Nişantaşı / İSTANBUL"}
            refYerAciklama={
              "VK108, 1970'li yıllardan bugüne Türk mimarisinin simgesel yapılarından birinin, Bilgili tecrübesi ve ustalığının, günümüzün teknolojisiyle birleştirildiği bir restorasyon projesidir."
            }
          />
        </div>
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/yonca-onuk.jpg"}
            imgLink2={"img/referanslar/yonca-onuk-2.jpg"}
            refBaslik={"Çelik Konstrüksiyon"}
            refAciklama={
              "Ana binaya bağlı olan yapı yıkılıp yerine yeni bir yapı inşa edilmiştir."
            }
            refProjeAdi={" Ek Bina"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Yonca-Onuk"}
            refFirmaAciklama={
              "firmasına 2019 yılında 6 aylık bir çalışma sonucu proje tamamlanmıştır."
            }
            refYer={"Tuzla / İSTANBUL"}
            refYerAciklama={
              "Tuzla tersanesinde bulunan  kompozit bot üretimi yapan aynı zamanda Türk Sahil Güvenlik Komutanlığına ihtiyacı doğrultusunda botlar üreten firmadır."
            }
          />
        </div>
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/dota-villa-lucille.jpg"}
            imgLink2={"img/referanslar/dota-villa-lucille-2.jpg"}
            refBaslik={"Mimari Çelik Konstrüksiyon Tasarım"}
            refAciklama={
              "Mimari tasarım olarak projenin içersinde bulunan, özel tasarım(korkuluk, uçan kapı, merdiven, bahçe dekorasyonu) projeleriyle uygulama gerçekleştirilmiştir."
            }
            refProjeAdi={"Çelik Konstrüksiyon Bina"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Dota"}
            refFirmaAciklama={
              "firmasına proje ve tasarımıyla gerçekleştirilmiştir."
            }
            refYer={"Tuzla / İSTANBUL"}
            refYerAciklama={
              "2000 yılında başlayan uluslararası lojistik firmasıdır.  "
            }
          />
        </div>
      </div>
      <hr className="talk" />
      {/* 2.Sıra */}
      <div className="row">
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/archepon-yapi-3.jpg"}
            imgLink2={"img/referanslar/archepon-yapi-4.jpg"}
            refBaslik={"Archepon"}
            refAciklama={
              "Çevre köylerdeki hayvan yetiştiricilerinin aracı kullanmadan kendi yetiştirdikleri hayvanlarını  satması için pazar yeri tasarlanmıştır."
            }
            refProjeAdi={"Balıkesir Hayvan Pazarı"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Balıkesir Belediyesi"}
            refFirmaAciklama={
              "firmasına 2012 tarihinde proje tasarımıyla uygulaması gerçekleştirilmiştir."
            }
            refYer={"Merkez / BALIKESİR"}
            refYerAciklama={
              "Türkiye'de ilk hayvan pazarı olarak faaliyet göstermektedir."
            }
          />
        </div>
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/archepon-yapi.jpg"}
            imgLink2={"img/referanslar/archepon-yapi-2.jpg"}
            refBaslik={"Archepon"}
            refAciklama={
              "Taş ocaklarının doğayı kirletmemesi için önlem açısından üstleri archepon çatı kaplama sistemi ile taş ocağının üstü kapatılmıştır."
            }
            refProjeAdi={"Kırıcı Taş ocağı Archepon Çatı"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Ercan Taş"}
            refFirmaAciklama={
              "firmasına 2012 tarihinde proje tasarımıyla uygulaması gerçekleştirilmiştir."
            }
            refYer={"Merkez / BALIKESİR"}
            refYerAciklama={
              "Mıcır üretme fabrikası faaliyetlerini sürdürmektedir."
            }
          />
        </div>
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/volksvagen-ek-bina.jpg"}
            imgLink2={"img/referanslar/archepon-cati.jpg"}
            refBaslik={"Çelik Konstrüksiyon ve Archepon"}
            refAciklama={
              "Volkswagen Edirne şubesinin ek bina proje uygulamasında çelik konstrüksiyon ve archepon çatı uygulaması gerçekleştirilmiştir.  "
            }
            refProjeAdi={"Volkswagen Ek Bina"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Mercan Mimarlık"}
            refFirmaAciklama={
              "firmasına 2012 tarihinde başlanılan proje 2013 tarihinde tamamlanmıştır."
            }
            refYer={"Merkez / EDİRNE"}
            refYerAciklama={
              "Ek Bina servis alanın genişletilmesi, ofis ve showroom olarak da faaliyetlerini sürdürmek için tasarlanmıştır."
            }
          />
        </div>
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/balparmak-celik-konstruksiyon.jpg"}
            imgLink2={"img/referanslar/1994_balparmak_ilk_bina.jpg"}
            refBaslik={"Çelik Konstrüksiyon"}
            refAciklama={
              "Faaliyet alanları,depolama,ofis olarak kullanılacak bina ve çevre tasarımı uygulamasıdır."
            }
            refProjeAdi={"Çelik Konstrüksiyon Bina"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Balparmak, Altıparmak Pazarlama"}
            refFirmaAciklama={
              "firmasına proje ve tasarımıyla gerçekleştirilmiştir."
            }
            refYer={"Çekmeköy / İSTANBUL"}
            refYerAciklama={
              "Türkiye'de bal tesislerinin  balparmak olarak açılımını gerçekleştirmiştir.Dünya markası olma yolunda ilk adım 1995 yılında atıldı. Bu yıl ilk ihracat gerçekleşti."
            }
          />
        </div>
      </div>
      <hr className="talk" />
      {/* 3.Sıra */}
      <div className="row">
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/fuar-stand.jpg"}
            imgLink2={"img/referanslar/fuar-stand-2.jpg"}
            refBaslik={"Fuar Zemin Standı"}
            refAciklama={
              "Fuar alanının sunumundaki yükseltimiş stand üzerinde satış ofisi yapılmıştır. 40x40 profilden projesi demontable şeklinde tasarlanmıştır."
            }
            refProjeAdi={"Yat Fuarı"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Trio Deniz"}
            refFirmaAciklama={
              "firmasına 2013 tarihinde TÜYAP Fuar Merkezinde proje uygulanmıştır. "
            }
            refYer={"Beylik düzü / İSTANBUL"}
            refYerAciklama={
              "Her yıl düzenlenen TÜYAP Yat Fuarı TRIO Deniz firması katılımını sağlamış. Sunumunda Acar Metal Tasarim firması zemin standı projesini  uygulamıştır."
            }
          />
        </div>
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/kartal-saglik-ocagi-2.jpg"}
            imgLink2={"img/referanslar/kartal-saglik-ocagi-3.jpg"}
            refBaslik={"Çelik Konstrüksiyon"}
            refAciklama={
              "Depreme dayanılık olarak geliştirilmiş çelik yapı projesidir. Sağlık ocakları projesi olarak yapılmıştır."
            }
            refProjeAdi={"Kartal Sağlık Ocağı"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Türkiye Cumhuriyeti Sağlık Bakanlığı"}
            refFirmaAciklama={"firmasına 2006 tarihinde proje uygulanmıştır."}
            refYer={"Kartal / İSTANBUL"}
            refYerAciklama={
              "Aile Sağlığı Merkezi olarak ilk olmak ve daima en iyisi olmak."
            }
          />
        </div>
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/hotel-caeli-kis-bahcesi-1.jpg"}
            imgLink2={"img/referanslar/hotel-caeli-kis-bahcesi-2.jpg"}
            refBaslik={"Kış Bahçesi"}
            refAciklama={
              "Otel Caeli kış bahçesi, dış cephe teras tasarımı tarafımız tarafından yapılmıştır."
            }
            refProjeAdi={"Hotel Caeli"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Toksöz Grup"}
            refFirmaAciklama={
              "firmasına 2013 tarihinde proje teslim edilmiştir."
            }
            refYer={"Eceabat / ÇANAKKALE"}
            refYerAciklama={
              "Şarap üreticisi olan Vine... olarak konum sağlamaktadır. Otelin en gözde yeri olan kış bahçesi müşterilerin sık sık kullandığı alan olmaktadır."
            }
          />
        </div>
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/tepeorenvillari.jpg"}
            imgLink2={"img/referanslar/tepeorenvillari2.jpg"}
            refBaslik={"Hafif Çelik Konstrüksiyon"}
            refAciklama={
              "Villa tasarımına göre  üç çeşit bina A,B,C tiplerinde  tasarlanmış olan çelik gövde,teras ve çevre korkuluk firmamız tarafından yapılmıştır."
            }
            refProjeAdi={"Tepeören Villaları"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Yonca Onuk"}
            refFirmaAciklama={
              "firmasına 2002 yılında projeye başlanıp 2006 yılında toplamda  100 villa proje uygulaması tamamlanmıştır."
            }
            refYer={"Tepeören / TUZLA"}
            refYerAciklama={
              " Proje, bölgenin en büyük alışveriş merkezlerinden biri olan Via       Port Alışveriş merkezine 5 dakika mesafede olup, Koç Lisesi, Şişli Terakki, Bilfen, Okan Üniversitesi, İstanbul Medeniyet Üniversitesi, Sabancı Üniversitesi, Sabiha Gökçen Havaalanı ve Formula Park' a yakın konumdadır."
            }
          />
        </div>
      </div>
      <hr className="talk" />
      {/* 4.Sıra */}
      <div className="row">
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/soho-house-garden.jpg"}
            imgLink2={"img/referanslar/soho-house-garden-3.jpg"}
            refBaslik={"Kış Bahçesi"}
            refAciklama={
              "Otelin dış bölgesinde bulunan dört mevsim faaliyet gösterecek. Kış bahçesi önünde pergule ve dinlenme alanı firmamız tarafından yapılmıştır."
            }
            refProjeAdi={"SOHO House Hotel - Kış Bahçesi"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"SOHO House"}
            refFirmaAciklama={
              "firmasına 2015 yılında  uygulaması gerçekleştirilmiştir."
            }
            refYer={"Nişantaşı / İSTANBUL"}
            refYerAciklama={
              "Beyoğlu'nun merkezindeki Palazzo Corpi'de bulunan Soho House İstanbul, üyelerin rahat edip, yiyip içebileceği ve buluşabileceği bir yer olarak faaliyet göstermektedir."
            }
          />
        </div>
        <div className="col-sm-3">
          <Firma
            imgLink={"img/referanslar/maslak.jpg"}
            imgLink2={"img/referanslar/cam-kanopi.jpg"}
            refBaslik={"Çelik Konstrüksiyon"}
            refAciklama={
              "Beybi GİZ Plaza girişi cam kanopi projesi imalatı ve montaj uygulaması yapılmıştır."
            }
            refProjeAdi={"Maslak Beybi Giz Plaza"}
            refProjeHakkinda={"Proje Hakkında"}
            refFirmaAdi={"Alt Yüklenici"}
            refFirmaAciklama={
              "firmasına 2012 tarihinde proje  uygulaması gerçekleştirilmiştir."
            }
            refYer={"Şişli / İSTANBUL"}
            refYerAciklama={
              "İstanbul’ da en son teknolojiye göre inşa edilerek hizmete girmiştir. Binamız ve yönetimi hakkında bilgi sahibi olmanız amacıyla sizlere aşağıdaki bilgiler sunulmuştur.."
            }
          />
        </div>
        <div className="col-sm-3"></div>
        <div className="col-sm-3"></div>
      </div>
    </div>
  );
}

export default RefFirma;
