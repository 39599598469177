import React from "react";
import Header from "./header/Header";
import Slider from "./slider/Slider";
import Main from "./main/Main";
import Footer from "./footer/Footer";

function Home() {
  return (
    <>
      <Header MenuLink="home" />

      <Slider />
      <Main />
      <Footer />
    </>
  );
}

export default Home;
