import React from "react";
import Header from "../home/header/Header";
import Footer from "../home/footer/Footer";

function Company() {
  return (
    <>
      <Header MenuLink="company" />
      {/* başlık */}
      <section className="page-header page-header-color page-header-quaternary page-header-more-padding custom-page-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h1>Kurumsal</h1>
            </div>
            <div className="col-lg-6">
              <ul className="breadcrumb pull-right">
                <li>
                  <a href="/">Anasayfa</a>
                </li>
                <li className="active">Kurumsal</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Sidebar */}
      <div role="main" className="main">
        <div className="container">
          <div className="row pt-2">
            <div className="col-lg-3">
              <aside
                className="sidebar"
                id="sidebar"
                data-plugin-sticky
                data-plugin-options="{'minWidth': 991, 'containerSelector': '.container', 'padding': {'top': 110}}"
              >
                <ul className="nav nav-list flex-column mb-4 show-bg-active">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-hash
                      data-hash-offset="110"
                      href="#hakkinda"
                    >
                      Hakkında
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-hash
                      data-hash-offset="110"
                      href="#vizyon-misyon"
                    >
                      Vizyon & Misyon
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-hash
                      data-hash-offset="110"
                      href="#politikalar"
                    >
                      Politikalar
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-hash
                      data-hash-offset="110"
                      href="#insankaynaklari"
                    >
                      İnsan Kaynakları
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-hash
                      data-hash-offset="110"
                      href="#cozumortaklari"
                    >
                      Çözüm Ortakları
                    </a>
                  </li>
                </ul>
              </aside>
            </div>
            {/* Sayfa İçi */}
            <div className="col-lg-9">
              <section id="hakkinda" className="mb-4">
                <h2 className="mb-0 text-color-dark">Hakkında - Biz Kimiz?</h2>
                <p className=" mb-0 mt-5 ">
                  Acar Metal Tasarım 2011 yılında kurulmuştur. Çelik
                  konstrüksiyon sektöründe proje,tasarım,imalat,montaj
                  hizmetleri vererek faaliyet alanında uluslararası standartlara
                  uyum ve sürekli gelişen kendini geliştiren ve daima başarıya
                  adım adım ilerleyen bir firma olmuştur. Uluslararası
                  standartlarda kurumsal yönetim, müşteri memnuniyeti,
                  sürdürülebilirlik ve toplumsal sorumluluk anlayışı ile
                  yürütmektedir. Günümüzde Türkiye’nin en büyük çelik
                  konstrüksiyon alanında yükselişini ve yaptıkları projelerle
                  başarılarına değer katmaya devam etmektir. Tasarım ve
                  dekorasyon alanı, demir dövme sanatı gibi benzer tasarımlarını
                  ürün haline getirip toptan satışını yapmaktadır.
                </p>
              </section>

              <hr className="solid tall" />

              <section id="vizyon-misyon" className="mb-4">
                <div className="row mt-4">
                  <div className="col-sm-6 text-center">
                    <div
                      className="wow feature-box feature-box-style-4 justify-content-center mt-4 appear-animation"
                      data-appear-animation="fadeInUp"
                      data-appear-animation-delay="0"
                    >
                      <span className="featured-boxes featured-boxes-style-4 p-0 m-0">
                        <span className="featured-box featured-box-primary featured-box-effect-6 p-0 m-0">
                          <span className="box-content p-0 m-0">
                            <i className="icon-featured icon-eye "></i>
                          </span>
                        </span>
                      </span>
                      <div className="feature-box-info">
                        <h2 className="mb-3 text-color-dark">Vizyon</h2>
                        <p className="mb-4">
                          Sektördeki yenilikleri uluslar arası boyutta takip
                          eden, yenilikleri müşterilerine en iyi şekilde sunan
                          firmamız; Kalite , hizmet anlayışı ve sektörde marka
                          olma bilinci ile fark yaratmayı, müşterilerinin istek
                          ve beklentileri doğrultusunda hizmet alanını ve
                          kalitesini sürekli geliştirmeyi sektöründe büyümeyi
                          ilke edinmiştir.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 text-center">
                    <div
                      className="wow feature-box feature-box-style-4 justify-content-center mt-4 appear-animation"
                      data-appear-animation="fadeInUp"
                      data-appear-animation-delay="300"
                    >
                      <span className="featured-boxes featured-boxes-style-4 p-0 m-0">
                        <span className="featured-box featured-box-primary featured-box-effect-6 p-0 m-0">
                          <span className="box-content p-0 m-0">
                            <i className="icons icon-featured icon-target icons"></i>
                          </span>
                        </span>
                      </span>
                      <div className="feature-box-info">
                        <h2 className="mb-3 text-color-dark">Misyon</h2>
                        <p className="mb-4">
                          Hizmet kalitesini sürekli geliştirerek müşterilerinin
                          beklentilerini en üst seviyede karşılamak,çalışma
                          kadrosuyla müşterilerimizle duygusal bir bağ yaratmak
                          ve sadakati sağlamak,yurt içinde büyüyerek aranılan
                          bir firma haline gelmektir.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <hr className="solid tall" />

              <section id="politikalar" className="mb-4">
                <h2 className="mb-4 text-color-dark">Politikalar</h2>
                <p>
                  - Tüm süreçlerde, müşterinin ihtiyaç ve beklentilerini tam
                  olarak karşılayan bir anlayış içinde güvenilir ve aranan firma
                  olmayı
                </p>
                <p>
                  <strong>Kalite Politikası</strong>
                </p>
                <p>
                  – Maliyetleri düşürmek ve karlılığı arttırmak için teknolojik
                  gelişmeleri yakından takip etmeyi ve uygulamayı
                </p>
                <p>
                  – Kalite sistemleri çerçevesinde tüm çalışanların daha yetkin
                  ve yeteneklerini en üst seviyede kullanabilen kişiler haline
                  gelmeleri için ekip çalışmasına önem vererek kalite düzeyini
                  sürekli yükseltmeyi
                </p>
                <p>– Tüm çalışanlarda kalite, bilincini tesis etmeyi</p>
                <p>
                  – Tedarikçi ve tedarikçilerle güvene dayalı bir işbirliği
                  içinde olmayı
                </p>
                <p>
                  – Firmamızın ve Türkiye’nin adını dünyaya daha çok duyurmak
                  amacına hizmet etmek üzere, mevcut teknik, idari ve mali
                  birikimlerimizi bütün dünyaya taşımak
                </p>
                <p>
                  – En uygun, en doğru ve en ekonomik çözümleri en hızlı biçimde
                  üreterek daha sonra ortaya çıkabilecek uygunsuzlukların önüne
                  geçmeyi
                </p>
                <p>
                  – Başlanan her projeyi zamanında, en üst düzey tekniklerle
                  projesine tam uyumlu, istenen kalitede ve bütçe sınırları
                  içinde eksiksiz tamamlamayı
                </p>
                <p>
                  – İçinde bulunduğumuz topluma ve çevreye saygılı, örnek bir
                  kuruluş olmayı ve İş hacmini sürekli geliştirerek, ülke
                  ekonomisine katkıda bulunmaktır
                </p>
                <div className="row mt-4"></div>
              </section>

              <hr className="solid tall" />
              <section id="insankaynaklari" className="mb-4">
                <h2 className="mb-0 text-color-dark">İnsan Kaynaklari</h2>

                <div className="row mt-4">
                  <div className="col-md-8">
                    <p>
                      - Nitelikli işgücünün çalışmak için tercih ettiği işveren
                      olmayı
                    </p>
                    <p>
                      {" "}
                      - Kurumsal değerlerimize sahip, işe en uygun nitelikteki
                      işgücünü seçmeyi ve işe almayı{" "}
                    </p>
                    <p>
                      {" "}
                      - Seçme ve işe almada küresel bakış açısı ve reaktif
                      yaklaşımla grubun geleceğe yönelik işgücü ihtiyaçlarını
                      karşılamayı hedeflemektedir{" "}
                    </p>
                    <p> - Çalışanların Gelişimine Yatırım Yapma</p>
                    <p>
                      {" "}
                      -Çalışanların sürekli gelişimi ve potansiyellerini
                      gerçekleştirmeleri için gelişim ortamı ve fırsatı yaratmak
                    </p>
                    <p>
                      {" "}
                      - Yöneticilerin, çalışanların gelişim sorumluluğunu
                      üstlendiği bir kültür yaratarak çalışan performansını
                      düzenli olarak izlemelerini sağlamak ve bununla ilgili
                      açık iletişimi desteklemek{" "}
                    </p>
                    <p>
                      {" "}
                      - Nitelikli, başarılı, küresel bir iş gücü ve lider havuzu
                      oluşturmak amacıyla çalışanların gelişimini yönlendirmek
                    </p>
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <span className="thumb-info thumb-info-side-image thumb-info-no-zoom no-borders mb-4">
                      <span className="thumb-info-side-image-wrapper p-0">
                        <img
                          src="img/kurumsal/ik.jpg"
                          className="img-fluid"
                          alt="İnsan Kaynakları"
                        />
                      </span>
                    </span>
                    <h4>Açık Pozisyonlar</h4>
                    <div
                      className="wow toggle toggle-quaternary"
                      data-plugin-toggle
                    >
                      <section className="toggle ">
                        <label> - </label>
                        <div className="toggle-content">
                          <p>-</p>
                        </div>
                      </section>
                      {/* 
                      <section className="toggle">
                        <label>Proje Yöneticisi</label>
                        <div className="toggle-content">
                          <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Facere, voluptatibus.
                          </p>
                        </div>
                      </section>
                      <section className="toggle">
                        <label>Saha Sorumlusu</label>
                        <div className="toggle-content">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </p>
                        </div>
                      </section> */}
                    </div>
                  </div>
                </div>
              </section>

              <hr className="solid tall" />

              <section id="cozumortaklari" className="mb-4">
                <h2 className="mb-0 text-color-dark">Çözüm Ortakları</h2>

                <div className="row mt-4">
                  <div className="content-grid content-grid-dashed col mt-4 mb-4">
                    <div className="row content-grid-row">
                      <div className="content-grid-item col-sm-4 text-center">
                        <img
                          className="img-fluid"
                          src="img/kurumsal/sbo.png"
                          alt="SBO Yatcılık"
                        />
                      </div>
                      <div className="content-grid-item col-sm-4 text-center">
                        <img
                          className="img-fluid"
                          src="img/kurumsal/seismodynamics.png"
                          alt="Sismodinamik"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Company;
