import React from "react";
import Modals from "./Modals";
function Urun({
  productName,
  category,
  categoryType,
  link,
  imgLink,
  modalImg2,
  modalImg3,
  modalImg4,
  modalEtiket1,
  modalEtiket2,
  modalMalzeme,
  modalAğırlık,
  modalRenk,
  modalOlcuCap,
  modalOlcuTaban,
  modalOlcuYukseklik,
  modalUrunOzellikleri,
  modalKullanimYeri1,
  modalKullanimYeri2,
}) {
  return (
    <div className={`col-lg-4 isotope-item ${categoryType}`}>
      <div className="portfolio-item">
        <span className="thumb-info thumb-info-lighten thumb-info-no-zoom  thumb-info-centered-icons">
          <span className="thumb-info-wrapper">
            <img src={imgLink} className="img-fluid" alt={productName} />

            <span className="thumb-info-title ">
              <span className="thumb-info-inner">{productName}</span>

              <span className="thumb-info-type">{category}</span>
            </span>

            <span className="thumb-info-action   thumb-info-action-icon-light">
              <Modals
                urunBasligi={productName}
                category={category}
                link={link}
                resLink={imgLink}
                modalImg2={modalImg2}
                modalImg3={modalImg3}
                modalImg4={modalImg4}
                modalEtiket1={modalEtiket1}
                modalEtiket2={modalEtiket2}
                modalMalzeme={modalMalzeme}
                modalAğırlık={modalAğırlık}
                modalRenk={modalRenk}
                modalOlcuCap={modalOlcuCap}
                modalOlcuTaban={modalOlcuTaban}
                modalOlcuYukseklik={modalOlcuYukseklik}
                modalUrunOzellikleri={modalUrunOzellikleri}
                modalKullanimYeri1={modalKullanimYeri1}
                modalKullanimYeri2={modalKullanimYeri2}
              />
            </span>
          </span>
        </span>
      </div>
    </div>
  );
}

export default Urun;
