import React from "react";
import Header from "../home/header/Header";
import Footer from "../home/footer/Footer";
import Iframe from "react-iframe";
function Contact() {
  return (
    <>
      <Header MenuLink="contact" />

      <section className="page-header page-header-color page-header-quaternary page-header-more-padding custom-page-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h1>İletişim</h1>
            </div>
            <div className="col-lg-6">
              <ul className="breadcrumb pull-right">
                <li>
                  <a href="/">Anasayfa</a>
                </li>
                <li className="active">iletişim</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div role="main" className="main">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <hr />

              <h4 className="heading-secondary">
                Şirket <strong>Adresi</strong>
              </h4>
              <ul className="list list-icons list-icons-style-3 mt-4">
                <li>
                  <i className="fas fa-map-marker-alt"></i>{" "}
                  <strong>Adres:</strong> Tepeören Mah, Ekşioğlu Erdem Sanayi
                  Sitesi, A-4 Blok No 6-7-8 <strong>Orhanlı / Tuzla</strong>
                </li>
                <li>
                  <i className="fas fa-phone"></i> <strong>Telefon:</strong> 0
                  (216) 304 26 44
                </li>
                <li>
                  <i className="far fa-envelope"></i> <strong>Email:</strong>{" "}
                  <a
                    className="heading-secondary"
                    href="mailto:info@acarmetaltasarim.com"
                  >
                    info@acarmetaltasarim.com
                  </a>
                </li>
                <li></li>
              </ul>
            </div>
            <hr />
            <div className="col-lg-6">
              <hr />
              <h4 className="heading-secondary">
                Çalışma <strong>Saatleri</strong>
              </h4>
              <ul className="list list-icons list-dark mt-4">
                <li>
                  <i className="far fa-clock"></i>{" "}
                  <strong>Pazartesi - Cuma</strong> - 8:30 / 17:30
                </li>
                <li>
                  <i className="far fa-clock"></i> <strong>Cumartesi</strong> -
                  8:30 / 13:00
                </li>
                <li>
                  <i className="far fa-clock"></i> <strong>Pazar</strong> -
                  Kapalı
                </li>
              </ul>
            </div>
          </div>

          <hr />
          <div id="googlemaps" className="google-map">
            {" "}
            {/*        <Iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12063.448354591634!2d29.389801!3d40.89684!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7b935b874a29c6c8!2sACAR+METAL+TASARIM!5e0!3m2!1str!2str!4v1545915515157"
              width="100%"
              height="400"
              frameborder="0"
              allowfullscreen=""
            ></Iframe> */}
            <Iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5035.710076868357!2d29.395128594650625!3d40.89493918338217!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7b935b874a29c6c8!2sACAR%20METAL%20TASARIM!5e0!3m2!1str!2str!4v1612527600239!5m2!1str!2str"
              width="100%"
              height="400"
              frameborder="0"
              style={{ border: "0" }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></Iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
