import React from "react";
import "./Slider.css";
function Slide2() {
  return (
    <li data-transition="fade">
      <img
        src="img/slides/yapi-2.jpg"
        alt="Urunler"
        data-bgposition="center center"
        data-bgfit="cover"
        data-bgrepeat="no-repeat"
        className="rev-slidebg"
      />

      <div
        className="tp-caption main-label slider2Cap"
        data-x="left"
        data-hoffset="100"
        data-y="center"
        data-voffset="-205"
        data-start="1000"
        style={{ zIndex: "5", fontSize: "40px" }}
        data-transform_in="y:[-300%];opacity:0;s:500;"
      >
        Ürünlerimiz
      </div>

      <div
        className="tp-caption tp-caption-overlay-opacity bottom-label slider2Urun"
        data-x="left"
        data-hoffset="100"
        data-y="center"
        data-voffset="-145"
        data-start="1500"
        data-transform_in="x:[-100%];opacity:0;s:500;"
      >
        <i className="fas fa-check icon-color "></i> Şömine
      </div>

      <div
        className="tp-caption tp-caption-overlay-opacity bottom-label slider2Urun2"
        data-x="left"
        data-hoffset="100"
        data-y="center"
        data-voffset="-100"
        data-start="1800"
        data-transform_in="x:[-100%];opacity:0;s:500;"
      >
        <i className="fas fa-check icon-color "></i> Ateş Çukuru
      </div>

      <div
        className="tp-caption tp-caption-overlay-opacity bottom-label slider2Urun3"
        data-x="left"
        data-hoffset="100"
        data-y="center"
        data-voffset="-55"
        data-start="2100"
        data-transform_in="x:[-100%];opacity:0;s:500;"
      >
        <i className="fas fa-check icon-color "></i> Mangal
      </div>

      <div
        className="tp-caption tp-caption-overlay-opacity bottom-label slider2Urun4"
        data-x="left"
        data-hoffset="100"
        data-y="center"
        data-voffset="-10"
        data-start="2400"
        data-transform_in="x:[-100%];opacity:0;s:500;"
      >
        <i className="fas fa-check icon-color "></i> Duvar Aksesuarları
      </div>

      <div
        className="tp-caption tp-caption-overlay-opacity bottom-label slider2Urun5"
        data-x="left"
        data-hoffset="100"
        data-y="center"
        data-voffset="35"
        data-start="2700"
        data-transform_in="x:[-100%];opacity:0;s:500;"
      >
        <i className="fas fa-check icon-color "></i> Dövme Demir Sanatı
      </div>

      <div
        className="tp-caption tp-caption-overlay-opacity bottom-label slider2Urun6"
        data-x="left"
        data-hoffset="100"
        data-y="center"
        data-voffset="80"
        data-start="3000"
        data-transform_in="x:[-100%];opacity:0;s:500;"
      >
        <i className="fas fa-check icon-color "></i> Saksı / Heykel
      </div>

      <a
        className="tp-caption btn  btn-outline custom-border-width font-weight-semibold btn-yellowgreen btn-lg sliBtn"
        data-hash
        data-hash-offset="85"
        href="/faaliyetler#urunler"
        data-x="left"
        data-hoffset="100"
        data-y="center"
        data-voffset="150"
        data-start="3500"
        data-whitespace="nowrap"
        data-transform_in="y:[100%];s:500;"
        data-transform_out="opacity:0;s:500;"
        /*  style="z-index: 5" */
        data-mask_in="x:0px;y:0px;"
      >
        Daha Fazlası İçin <i className="fas fa-long-arrow-alt-center"></i>
      </a>
    </li>
  );
}

export default Slide2;
