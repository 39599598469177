import React from "react";
import "./Slider.css";
function Slide3() {
  return (
    <li data-transition="fade">
      <img
        src="img/slides/yapi-5.jpg"
        alt=""
        data-bgposition="center center"
        data-bgfit="cover"
        data-bgrepeat="no-repeat"
        className="rev-slidebg"
      />

      <div
        className="tp-caption"
        data-x="center"
        data-hoffset="['-150','-150','-150','-220']"
        data-y="180"
        data-start="1000"
        data-transform_in="x:[-300%];opacity:0;s:500;"
      ></div>

      <div
        className="tp-caption top-label smallSlider3Cap"
        data-x="center"
        data-y="172"
        data-fontsize="['24','24','24','36']"
        data-start="500"
        data-transform_in="y:[-300%];opacity:0;s:500;"
      >
        Projeler
      </div>

      {/*     <div
        className="tp-caption"
        data-x="center"
        data-hoffset="['150','150','150','220']"
        data-y="180"
        data-start="1000"
        data-transform_in="x:[300%];opacity:0;s:500;"
      ></div> */}

      <div
        className="tp-caption main-label slider3Cap  mb-3"
        data-x="center"
        data-y="['210','210','210','230']"
        data-start="1500"
        data-whitespace="nowrap"
        data-fontsize="['62','62','62','92']"
        data-transform_in="y:[100%];s:500;"
        data-transform_out="opacity:0;s:500;"
        data-mask_in="x:0px;y:0px;"
      >
        Proje, Tasarım, Resterasyon
      </div>

      <div
        className="tp-caption tp-caption-overlay-opacity bottom-label slider3Cap2"
        data-x="center"
        data-y="['280','280','280','315']"
        data-voffset="5"
        data-start="2000"
        data-fontsize="['23','23','23','30']"
        data-transform_idle="o:1;"
        data-transform_in="y:[100%];z:0;rZ:-35deg;sX:1;sY:1;skX:0;skY:0;s:600;e:Power4.easeInOut;"
        data-transform_out="opacity:0;s:500;"
        data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
        data-splitin="chars"
        data-splitout="none"
        data-responsive_offset="on"
        data-elementdelay="0.05"
      >
        Yeni çözümler, yeni projeler ve yeni tasarımlar ile karşınızdayız!
      </div>
      <a
        className="tp-caption btn btn-lg btn-secondary btn-slider-action sli3Btn mt-3 "
        data-hash
        data-hash-offset="85"
        href="/faaliyetler#projeler"
        data-x="center"
        data-hoffset="0"
        data-y="center"
        data-voffset="['100','100','100','190']"
        data-start="2200"
        data-whitespace="nowrap"
        data-paddingtop="['20','20','20','40']"
        data-paddingbottom="['20','20','20','40']"
        data-paddingleft="['25','25','25','45']"
        data-paddingright="['25','25','25','45']"
        data-fontsize="['22','22','22','52']"
        data-transform_in="y:[100%];s:500;"
        data-transform_out="opacity:0;s:500;"
        /* style="z-index: 5" */
        data-mask_in="x:0px;y:0px;"
      >
        Projeler için
      </a>
    </li>
  );
}

export default Slide3;
