import React from "react";
import "./Slider.css";
function Slide() {
  return (
    <li data-transition="fade">
      <img
        src="img/slides/yapi-1.jpg"
        alt="Hizmetler"
        data-bgposition="center center"
        data-bgfit="cover"
        data-bgrepeat="no-repeat"
        data-kenburns="on"
        data-duration="9000"
        data-ease="Linear.easeNone"
        data-scalestart="115"
        data-scaleend="100"
        data-rotatestart="0"
        data-rotateend="0"
        data-offsetstart="0 400px"
        data-offsetend="0 -400px"
        data-bgparallax="0"
        className="rev-slidebg"
      />

      <div
        className="tp-caption tp-caption-overlay tp-caption-overlay-primary main-label slider1Cap"
        data-x="center"
        data-y="265"
        data-start="1000"
        data-whitespace="nowrap"
        data-transform_in="y:[100%];s:500;"
        data-transform_out="opacity:0;s:500;"
        data-mask_in="x:0px;y:0px;"
      >
        ACAR METAL TASARIM
      </div>

      <div
        className="tp-caption tp-caption-overlay-opacity bottom-label slider1Cap2"
        data-x="center"
        data-y="358"
        data-start="2000"
        data-transform_in="y:[100%];opacity:0;s:500;"
      >
        Yenilenmeyi keşfedin.
      </div>
    </li>
  );
}

export default Slide;
