import React from "react";

function Proje({
  imgProje,
  imgProje2,
  projeAdi,
  projeAciklama,
  projeAciklama2,
  projeYer,
}) {
  return (
    <div className="row mt-5">
      <div className="col-lg-4">
        <div
          className="owl-carousel owl-theme"
          data-plugin-options="{'items': 1, 'margin': 10, 'animateOut': 'fadeOut', 'autoplay': true, 'autoplayTimeout': 3000}"
        >
          <div>
            <span className="img-thumbnail d-block">
              <img alt="" className="img-fluid" src={imgProje} />
            </span>
          </div>
          <div>
            <span className="img-thumbnail d-block">
              <img alt="" className="img-fluid" src={imgProje2} />
            </span>
          </div>
        </div>
      </div>

      <div className="col-lg-8">
        <div className="portfolio-info">
          <div className="row">
            <div className="col-md-6">
              <h5>{projeAdi}</h5>
            </div>
          </div>
        </div>
        <p className="mt-2 text-justify">{projeAciklama}</p>
        <p className="mt-2 text-justify">{projeAciklama2}</p>

        <ul className="portfolio-details">
          <li>
            <ul className="list list-inline list-icons">
              <li className="list-inline-item">
                <h5 className="mt-2 mb-1">Proje Adı</h5>
                <p>{projeAdi}</p>
              </li>

              <li className="list-inline-item">
                <h5 className="mt-2 mb-1">Yer</h5>
                <p>{projeYer}</p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Proje;
