import React from "react";
import WOW from "../../WOW";
import Hizmetler from "./Hizmetler";
import Projeler from "./Projeler";
import Counter from "./Counter";
import Urunler from "./Urunler";
import RefLogo from "./RefLogo";
function Main() {
  new WOW().init();
  return (
    <>
      {/* Hizmetler - 1 */}
      <Hizmetler />

      {/* Counter */}
      <Counter />

      {/* Projeler - 1 */}
      <Projeler />

      <Urunler />
      <RefLogo />
    </>
  );
}

export default Main;
