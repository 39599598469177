import React from "react";
import Slide from "./Slide";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";
function Slider() {
  return (
    <div role="main" className="main">
      <div className="slider-container rev_slider_wrapper">
        <div
          id="revolutionSlider"
          className="slider rev_slider"
          data-version="5.4.7"
          data-plugin-revolution-slider
          data-plugin-options="{'sliderLayout': 'fullscreen', 'responsiveLevels': [4096,1350,992,500]}"
        >
          <ul>
            <Slide />
            <Slide3 />
            <Slide2 />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Slider;
