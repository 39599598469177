import React from "react";
import Proje from "./Proje";
function Projeler() {
  return (
    <div role="main" className="main">
      <div className="container">
        <section id="projeler" className="mt-2">
          <hr className="mt-5 tall " />
        </section>
        <div className="row pt-2">
          {/* Sidebar */}
          <div className="col-lg-3">
            <aside
              className="sidebar"
              id="sidebar"
              data-plugin-sticky
              data-plugin-options="{'minWidth': 991, 'containerSelector': '.container', 'padding': {'top': 110}}"
            >
              <h4 className="heading-primary mt-4">
                <strong>Projeler</strong>
              </h4>
              <ul className="nav nav-list flex-column mb-4 show-bg-active">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-hash
                    data-hash-offset="110"
                    href="#a-firma"
                  >
                    Day Group
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-hash
                    data-hash-offset="110"
                    href="#b-firma"
                  >
                    Işık Peyzaj
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-hash
                    data-hash-offset="110"
                    href="#c-firma"
                  >
                    Tarman İnşaat
                  </a>
                </li>
              </ul>
            </aside>
          </div>
          <div className="col-lg-9">
            <h2>Projeler</h2>

            <section id="a-firma" className="mb-2 mt-2">
              <Proje
                imgProje={"img/projeler/day-group-fulya-bina.jpg"}
                imgProje2={"img/projeler/day-group-fulya-bina-2.jpg"}
                projeAdi={"Day Group - Fulya Binası"}
                projeAciklama={
                  "Fulya'da bulunan Day Group firmasına ait, tamamen kentsel dönüşüm mantığı altında planlanmış. Proje kapsamında; deprem, yangın ve bina güvenliği olmayan 6 farklı riskli bina alınıp, günümüz yönetmeliklerine ve modern teknolojiye uygun, güvenilir ve konforlu bir şekilde yeniden hayata geçirilmeye çalışılmaktadır.  "
                }
                projeAciklama2={
                  "Çatısı çelik konstrüksiyon yapılmaktadır. Çevresine çelik korkuluk proje uygun şekilde yapılmaktadır."
                }
                projeYer={"Fulya / BEŞİKTAŞ"}
              />
              <hr className="mt-5 tall " />
            </section>
            <section id="b-firma" className="mb-2">
              <Proje
                imgProje={"img/projeler/galataport-saksilik.jpg"}
                imgProje2={"img/projeler/galataport-saksilik-2.jpg"}
                projeAdi={"Işık Peyzaj - Galataport Saksılık "}
                projeAciklama={
                  "Galataport projesinde tasarlanan bütün deniz çevresi boyunca  dikilecek ağaçların  taşıyıcı saksı uygulaması yapılmaktadır. Taşıyıcı saksılar galvaniz ve korten malzemeden olmak üzere 2 türde tasarlanmıştır. "
                }
                projeAciklama2={
                  "Galataport, yerel kültürün dünyanın geri kalanıyla incelikle birleştiği İstanbul'un yepyeni atan kalbi olmayı hedefliyor. İstanbullular için eşsiz bir deneyim vaad ediyor."
                }
                projeYer={"Tophane / KARAKÖY"}
              />
              <hr className="mt-5 tall " />
            </section>
            <section id="c-firma" className="mb-2">
              <Proje
                imgProje={"img/projeler/tarman-insaat-pangalti.jpg"}
                imgProje2={"img/projeler/tarman-insaat-pangalti-3.jpg"}
                projeAdi={"Tarman İnşaat - Resterasyon"}
                projeAciklama={
                  "İstanbul'un tarihi evlerinden  Pangaltı'nda bulunan resterasyon işlemi yapılacak olan binanın giriş kapısı, balkon korkulukları  ve çelik çatı projesi uygulanmaktadır."
                }
                projeAciklama2={"Tarihi ev yenilenmeye doğru ilerliyor."}
                projeYer={"Pangaltı / BEŞİKTAŞ"}
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projeler;
