import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    position: "absolute",
    top: "100px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function Modals({
  urunBasligi,
  category,
  link,
  resLink,
  modalImg2,
  modalImg3,
  modalImg4,
  modalEtiket1,
  modalEtiket2,
  modalOlcuCap,
  modalOlcuTaban,
  modalOlcuYukseklik,
  modalMalzeme,
  modalAğırlık,
  modalRenk,
  modalUrunOzellikleri,
  modalKullanimYeri1,
  modalKullanimYeri2,
}) {
  const classNamees = useStyles();

  const [open, setOpen] = React.useState(false);
  const [original1, setOriginal1] = React.useState("");
  const [original2, setOriginal2] = React.useState("");
  const [original3, setOriginal3] = React.useState("");
  const [original4, setOriginal4] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
    setOriginal1(resLink);
    setOriginal2(modalImg2);
    setOriginal3(modalImg3);
    setOriginal4(modalImg4);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const images = [
    {
      original: original1,
      thumbnail: original1,
    },
    {
      original: original2,
      thumbnail: original2,
    },
    {
      original: original3,
      thumbnail: original3,
    },
    {
      original: original4,
      thumbnail: original4,
    },
  ];
  const body = (
    <div role="main" className={`main ${classNamees.paper}`}>
      <div className="container">
        <div className="row mb-5">
          <div className="col">
            <div className="portfolio-title">
              <div className="row">
                <div className="col-lg-12 text-center modal-header">
                  <h3 className="mb-0">
                    {" "}
                    <strong>ÜRÜN KODU : </strong> {urunBasligi}
                  </h3>
                  <a href={link} className="close btn-sm" onClick={handleClose}>
                    <i className="icon-close icons"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <ImageGallery
          autoPlay={false}
          showFullscreenButton={false}
          showPlayButton={false}
          useTranslate3Dinfinite={true}
          sizes={"20%"}
          items={images}
          key={images}
        />
      </div>

      <hr className="mt-3 mb-2 talk" />

      <div className="container">
        <div className="row">
          <div className="col">
            <div className="portfolio-info mt-4">
              <div className="row"></div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-9">
            <h4 className="mt-4 mb-2 text-uppercase">
              {" "}
              <strong>Ürün</strong> Özellikleri
            </h4>
            <ul className="list list-icons list-icons-sm  mt-3">
              <li>
                <i className="fas fa-caret-right"></i> {modalMalzeme}
              </li>
              <li>
                {" "}
                <i className="fas fa-caret-right"></i>
                {modalAğırlık}
              </li>
              <li>
                {" "}
                <i className="fas fa-caret-right"></i>
                {modalRenk}
              </li>
            </ul>
            <p className="mt-0 mb-2 text-justify">{modalUrunOzellikleri}</p>
          </div>

          <div className="col-lg-3">
            <h4 className="mb-2 text-uppercase">
              <strong>Ölçü</strong> ve Etiket
            </h4>

            <ul className="list list-inline ">
              <li>
                <i className="icon-pin icons mr-2"></i>
                {modalOlcuCap}
              </li>
              <li>
                <i className="icon-pin icons mr-2"></i>
                {modalOlcuTaban}
              </li>
              <li>
                <i className="icon-pin icons mr-2"></i>
                {modalOlcuYukseklik}
              </li>
              <li>
                <i className="fas fa-tags"></i>{" "}
                <span className="badge badge-dark">
                  <strong></strong>
                  {category}
                </span>{" "}
                <span className="badge badge-dark">{modalEtiket1}</span>{" "}
                <span className="badge badge-dark">{modalEtiket2}</span>
              </li>
            </ul>

            <div className="portfolio-item">
              <ul className="portfolio-details">
                <li>
                  <h4 className="mt-0 mb-2 text-uppercase">
                    <strong>Kullanım</strong> Yeri
                  </h4>

                  <ul className="list list-inline list-icons">
                    <li className="list-inline-item">
                      <i className="fas fa-home"></i>

                      {modalKullanimYeri1}
                    </li>
                    <li className="list-inline-item">
                      <i className="fas fa-building"></i>

                      {modalKullanimYeri2}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-default"
          onClick={handleClose}
          data-dismiss="modal"
        >
          Kapat
        </button>
      </div>
    </div>
  );
  return (
    <div>
      <button
        type="button"
        className="btn btn-light btn-lg btn-block"
        onClick={handleOpen}
      >
        <i className="fas fa-plus"></i>
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classNamees.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {body}
      </Modal>
      <Fade in={open}>
        <div className={classNamees.paper}>
          <h2 id="transition-modal-title">Transition modal</h2>
          <p id="transition-modal-description">
            react-transition-group animates me.
          </p>
        </div>
      </Fade>
    </div>
  );
}

export default Modals;
