import React from "react";

function Alan({ alan, resim, ek, animasyon, sure }) {
  return (
    <>
      {/*   className="wow appear-animation" data-appear-animation="fadeInUp"
      data-appear-animation-delay="300" */}
      <div className="col-md mb-4">
        <span
          className={`wow thumb-info  ${ek}`} /* thumb-info-hide-wrapper-bg  */
          data-appear-animation={animasyon}
          data-appear-animation-delay={sure}
        >
          <span className="thumb-info-wrapper">
            <img
              src={resim}
              className="img-fluid"
              alt=""
              style={{ width: "300px", height: "180px" }}
            />
            <span className="thumb-info-title">
              <span className="thumb-info-inner">{alan}</span>
              {/*     <span className="thumb-info-type">Project Type</span> */}
            </span>
          </span>
        </span>
      </div>
    </>
  );
}

export default Alan;
