import { Home, Company, Services, Reference, Contact } from "./components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="body">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/kurumsal" component={Company} />
          <Route path="/faaliyetler" component={Services} />
          <Route path="/referans" component={Reference} />
          <Route path="/iletisim" component={Contact} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
