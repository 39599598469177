import React from "react";

function Hizmetler() {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <hr className="mt-5" />
          <div className="featured-boxes featured-boxes-style-4">
            <div className="row">
              <div className="col-lg-3">
                <div
                  className="wow featured-box featured-box-quaternary  featured-box-effect-5 appear-animation"
                  data-appear-animation="fadeInUp"
                  data-appear-animation-delay="300"
                >
                  <div className="box-content">
                    <a href="/faaliyetler#hizmetler">
                      <i className="icon-featured fas fa-home"></i>
                    </a>
                    <h4>Çelik Konstrüksiyon</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div
                  className="wow featured-box featured-box-quaternary featured-box-effect-5 appear-animation"
                  data-appear-animation="fadeInUp"
                  data-appear-animation-delay="600"
                >
                  <div className="box-content">
                    <a href="/faaliyetler#hizmetler">
                      <i className="icon-featured fas fa-building"></i>
                    </a>
                    <h4>Çelik Güçlendirme</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div
                  className="wow featured-box featured-box-quaternary featured-box-effect-5 appear-animation"
                  data-appear-animation="fadeInUp"
                  data-appear-animation-delay="900"
                >
                  <div className="box-content">
                    <a href="/faaliyetler#hizmetler">
                      <i className="icon-featured fas fa-pencil-alt"></i>
                    </a>
                    <h4>Çelik Mimari Tasarım</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div
                  className="wow featured-box featured-box-quaternary featured-box-effect-5 appear-animation"
                  data-appear-animation="fadeInUp"
                  data-appear-animation-delay="1200"
                >
                  <div className="box-content">
                    <a href="/faaliyetler#hizmetler">
                      <i className="icon-featured fab fa-accusoft"></i>
                    </a>
                    <h4>Çelik Çatı</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hizmetler;
