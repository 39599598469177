import React, { useState, useEffect } from "react";

function Header({ MenuLink }) {
  const [Home, setHome] = useState("");
  const [Company, setCompany] = useState("");
  const [Services, setServices] = useState("");
  const [Reference, setReference] = useState("");
  const [Contact, setContact] = useState("");

  useEffect(() => {
    switch (MenuLink) {
      case "home":
        setHome("active");

        break;
      case "company":
        setCompany("active");
        break;
      case "services":
        setServices("active");
        break;
      case "reference":
        setReference("active");
        break;
      case "contact":
        setContact("active");
        break;

      default:
        return "";
    }
  }, [MenuLink]);

  return (
    <header
      id="header"
      className="header-narrow header-semi-transparent"
      data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyStartAt': 1, 'stickySetTop': '1'}"
    >
      <div className="header-body">
        <div className="header-container container">
          <div className="header-row">
            <div className="header-column">
              <div className="header-row">
                <div className="header-logo">
                  <a href="/">
                    <img
                      alt="Acar Metal Tasarım"
                      width="150"
                      height="70"
                      src="img/logo/acar-metal-tasarim-logo.png"
                      title="Acar Metal Tasarım"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="header-column justify-content-end">
              <div className="header-row">
                <div className="header-nav header-nav-stripe">
                  <div className="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1">
                    <nav className="collapse">
                      <ul className="nav nav-pills" id="mainNav">
                        <li className="nav-link">
                          <a className={`nav-link ${Home}`} href="/">
                            Anasayfa
                          </a>
                        </li>
                        <li className="nav-link">
                          <a href="/kurumsal" className={`nav-link ${Company}`}>
                            Kurumsal
                          </a>
                        </li>
                        <li className="nav-link dropdown dropdown-full-color dropdown-secondary ">
                          <a
                            className={`nav-link dropdown-item  dropdown-toggle ${Services}`}
                            href="/faaliyetler"
                          >
                            Faaliyetler
                          </a>
                          <ul className="dropdown-menu dropdown_menu dropdown_menu--animated  dropdown-animation">
                            <li className="dropdown_item-1">
                              <a
                                className="dropdown-item"
                                href="/faaliyetler#hizmetler"
                              >
                                Hizmetler
                              </a>
                            </li>
                            <li className="dropdown_item-2">
                              <a
                                className="dropdown-item"
                                href="/faaliyetler#projeler"
                              >
                                Projeler
                              </a>
                            </li>
                            <li className="dropdown_item-3">
                              <a
                                className="dropdown-item"
                                href="/faaliyetler#urunler"
                              >
                                Ürünler
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-link">
                          <a
                            className={`nav-link ${Reference}`}
                            href="/referans"
                          >
                            Referans
                          </a>
                        </li>
                        <li className="nav-link">
                          <a className={`nav-link ${Contact}`} href="/iletisim">
                            iletişim
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>

                  <button
                    className="btn header-btn-collapse-nav"
                    data-toggle="collapse"
                    data-target=".header-nav-main nav"
                  >
                    <i className="fas fa-bars"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
