import React from "react";
import Header from "../home/header/Header";

import Hizmetler from "./hizmetler/Hizmetler";
import Urunler from "./urunler/Urunler";
import Projeler from "./projeler/Projeler";

import Footer from "../home/footer/Footer";

function Services() {
  return (
    <>
      <Header MenuLink="services" />
      {/* başlık */}
      <section className="page-header page-header-color page-header-quaternary page-header-more-padding custom-page-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h1>Hizmetler</h1>
            </div>
            <div className="col-lg-6">
              <ul className="breadcrumb pull-right">
                <li>
                  <a href="/">Anasayfa</a>
                </li>
                <li className="active">Hizmetler</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Hizmetler />
      <Projeler />
      <div className="container">
        <hr className="tall " />
        <section className="mt-2">
          <div className="heading heading-secondary heading-border heading-bottom-border">
            <h4>
              <strong className="heading-secondary"> NOT :</strong> Ürünlerimiz
              de toptan satışımız bulunmaktadır. Perakende(tekli) satışımız
              yoktur. Minimum 25 adet ile sınırlıdır. Tasarımlara uygun ürün
              geliştirimi ve üretimi yapılmaktadır. Daha fazla bilgi almak için
              bize ulaşın.
            </h4>
          </div>
        </section>
      </div>
      <Urunler />

      <Footer />
    </>
  );
}

export default Services;
