import React from "react";

function Firma({
  imgLink,
  imgLink2,
  refBaslik,
  refAciklama,
  refProjeAdi,
  refProjeHakkinda,
  refFirmaAdi,
  refFirmaAciklama,
  refYer,
  refYerAciklama,
}) {
  return (
    <span
      className="wow thumb-info appear-animation"
      data-appear-animation="flipInX"
      data-appear-animation-delay="0"
      data-appear-animation-duration="2s"
    >
      <div
        className="owl-carousel owl-theme nav-inside float-left mr-4 mb-2"
        data-plugin-options="{'items': 1, 'margin': 10, 'animateOut': 'fadeOut'}"
      >
        <div>
          <img className="img-fluid rounded" src={imgLink} alt={imgLink} />
        </div>
        <div>
          <img className="img-fluid rounded" src={imgLink2} alt={imgLink} />
        </div>
      </div>
      <div className="toggle toggle-quaternary" data-plugin-toggle>
        <section className="toggle">
          <label>{refBaslik}</label>
          <div className="toggle-content">
            <h4>{refProjeAdi}</h4>

            <dl>
              <dt>{refProjeHakkinda}</dt>
              <dd className="text-justify">{refAciklama}</dd>
              <dt>{refFirmaAdi}</dt>
              <dd className="text-justify">{refFirmaAciklama}</dd>

              <dt>{refYer}</dt>
              <dd className="text-justify">{refYerAciklama}</dd>
            </dl>
          </div>
        </section>
      </div>
    </span>
  );
}

export default Firma;
