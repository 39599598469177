import React from "react";
import Urun from "./urun/Urun";
function Urunler() {
  return (
    <>
      <div className="container">
        <section id="urunler" className="mt-2">
          <hr className="tall " />
        </section>

        <div className="row">
          {/* Sidebar */}
          <div className="col-lg-3">
            <aside
              className="sidebar"
              id="sidebar"
              data-plugin-sticky
              data-plugin-options="{'minWidth': 991, 'containerSelector': '.container', 'padding': {'top': 110}}"
            >
              <h4 className="heading-primary mt-4">
                <strong>Ürünler</strong>
              </h4>

              <ul
                className="nav nav-list flex-column mb-4 sort-source"
                data-sort-id="portfolio"
                data-option-key="filter"
                data-plugin-options="{'layoutMode': 'fitRows', 'filter': '*'}"
              >
                <li className="nav-item" data-option-value="*">
                  <a className="nav-link active" href="#urunler">
                    Tüm Ürünler
                  </a>
                </li>
                <li
                  className="nav-item"
                  data-option-value=".ates-cukuru-bahce-somine"
                >
                  <a className="nav-link" href=" #ates-cukuru-bahce-somine ">
                    Ateş Çukuru & Bahçe Şömine
                  </a>
                </li>
                <li className="nav-item" data-option-value=".ates-bacasi">
                  <a className="nav-link" href="#ates-bacasi ">
                    Ateş Bacası
                  </a>
                </li>
                <li
                  className="nav-item"
                  data-option-value=".corten-heykel-yapi"
                >
                  <a className="nav-link" href=" #corten-heykel-yapi ">
                    Corten Heykel Yapı
                  </a>
                </li>
                <li
                  className="nav-item"
                  data-option-value=".metal-duvar-aksesuar"
                >
                  <a className="nav-link" href=" #metal-duvar-aksesuar ">
                    Metal Duvar Aksesuarı
                  </a>
                </li>

                <li className="nav-item" data-option-value=".masa-tabure">
                  <a className="nav-link" href=" #masa-tabure ">
                    Masa & Tabure
                  </a>
                </li>
                <li className="nav-item" data-option-value=".mumluk">
                  <a className="nav-link" href=" #mumluk ">
                    Mumluk
                  </a>
                </li>
              </ul>
            </aside>
          </div>
          {/* Urun */}
          <div className="col-lg-9 mt-4">
            <div className="sort-destination-loader sort-destination-loader-showing">
              <div
                className="row portfolio-list sort-destination"
                data-sort-id="portfolio"
              >
                {/* Ateş Çukuru & Bahçe Şöminesi */}

                <Urun
                  productName="#AC-003"
                  category="Ateş Çukuru"
                  categoryType="ates-cukuru-bahce-somine"
                  link="#ates-cukuru-bahce-somine"
                  imgLink="img/urunler/ates-cukuru-bahce-somine/A-C-003.jpg"
                  modalImg2="img/urunler/ates-cukuru-bahce-somine/A-C-003-2.jpg"
                  modalImg3="img/urunler/ates-cukuru-bahce-somine/A-C-003-3.jpg"
                  modalImg4="img/urunler/ates-cukuru-bahce-somine/A-C-003-4.jpg"
                  modalEtiket1={"Bahçe Şöminesi"}
                  modalEtiket2={"Ateş çukuru"}
                  modalUrunOzellikleri=" Ateş çukuru veya bahçe şöminesi olarak da kullanılan modelimizdir. Evinizin bahçesinde veya açık hava alanda kullanıma uygundur. Ürünlerimizin ölçü ve ebatlarında tasarıma göre değişkenlik göstermektedir.    
                "
                  modalMalzeme="Malzeme : Sac - 3mm / 4mm / 5mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="Çap : 65cm X 55cm"
                  modalOlcuTaban="Taban : 45cm X 45cm"
                  modalOlcuYukseklik="Yükseklik : 70cm"
                />
                <Urun
                  productName="#AC-004"
                  category="Ateş Çukuru"
                  categoryType="ates-cukuru-bahce-somine"
                  link="#ates-cukuru-bahce-somine"
                  imgLink="img/urunler/ates-cukuru-bahce-somine/A-C-004.jpg"
                  modalImg2="img/urunler/ates-cukuru-bahce-somine/A-C-004-2.jpg"
                  modalImg3="img/urunler/ates-cukuru-bahce-somine/A-C-004-3.jpg"
                  modalImg4="img/urunler/ates-cukuru-bahce-somine/A-C-004-5.jpg"
                  modalEtiket1={"Bahçe Şöminesi"}
                  modalEtiket2={"Ateş çukuru"}
                  modalUrunOzellikleri=" Ateş çukuru veya bahçe şöminesi olarak da kullanılan modelimizdir. Evinizin bahçesinde veya açık hava alanda kullanıma uygundur. Ürünlerimizin ölçü ve ebatlarında tasarıma göre değişkenlik göstermektedir.    
                  "
                  modalMalzeme="Malzeme : Sac - 3mm / 4mm / 5mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="Çap : 78cm"
                  modalOlcuTaban="Taban : 78cm"
                  modalOlcuYukseklik="Yükseklik : 40cm"
                />
                <Urun
                  productName="#AC-008"
                  category="Ateş Çukuru"
                  categoryType="ates-cukuru-bahce-somine"
                  link="#ates-cukuru-bahce-somine"
                  imgLink="img/urunler/ates-cukuru-bahce-somine/A-C-008.jpg"
                  modalImg2="img/urunler/ates-cukuru-bahce-somine/A-C-008-2.jpg"
                  modalImg3="img/urunler/ates-cukuru-bahce-somine/A-C-008-3.jpg"
                  modalImg4="img/urunler/ates-cukuru-bahce-somine/A-C-008-4.jpg"
                  modalEtiket1={"Bahçe Şöminesi"}
                  modalEtiket2={"Ateş çukuru"}
                  modalUrunOzellikleri="Demontable modelimiz olan parçalanıp tekrar toplaya bileceğiniz modelimizdir. Ateş çukuru veya bahçe şöminesi olarak da kullanılan modeller arasındadır. Evinizin bahçesinde veya açık hava alanda kullanıma uygundur. Ürünlerimizin ölçü ve ebatlarında tasarıma göre değişkenlik göstermektedir."
                  modalMalzeme="Malzeme : Sac - 3mm / 4mm / 5mm - Corten Sac"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="En : 64cm"
                  modalOlcuTaban="Derinlik : 64cm"
                  modalOlcuYukseklik="Yükseklik : 44cm"
                />
                <Urun
                  productName="#AC-009"
                  category="Ateş Çukuru"
                  categoryType="ates-cukuru-bahce-somine"
                  link="#ates-cukuru-bahce-somine"
                  imgLink="img/urunler/ates-cukuru-bahce-somine/A-C-009.jpg"
                  modalImg2="img/urunler/ates-cukuru-bahce-somine/A-C-009-2.jpg"
                  modalImg3="img/urunler/ates-cukuru-bahce-somine/A-C-009-3.jpg"
                  modalImg4="img/urunler/ates-cukuru-bahce-somine/A-C-009-4.jpg"
                  modalEtiket1={"Bahçe Şöminesi"}
                  modalEtiket2={"Ateş çukuru"}
                  modalUrunOzellikleri="Ateş çukuru veya bahçe şöminesi olarak da kullanılan modeller arasındadır. Evinizin bahçesinde veya açık hava alanda kullanıma uygundur. Ürünlerimizin ölçü ve ebatlarında tasarıma göre değişkenlik göstermektedir."
                  modalMalzeme="Malzeme : Sac - 3mm / 4mm / 5mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="En : 61cm X 45cm"
                  modalOlcuTaban="Derinlik : - "
                  modalOlcuYukseklik="Yükseklik : 30cm"
                />
                <Urun
                  productName="#AC-0010"
                  category="Ateş Çukuru"
                  categoryType="ates-cukuru-bahce-somine"
                  link="#ates-cukuru-bahce-somine"
                  imgLink="img/urunler/ates-cukuru-bahce-somine/A-C-0010.jpg"
                  modalImg2="img/urunler/ates-cukuru-bahce-somine/A-C-0010-2.jpg"
                  modalImg3="img/urunler/ates-cukuru-bahce-somine/A-C-0010-4.jpg"
                  modalImg4="img/urunler/ates-cukuru-bahce-somine/A-C-0010-3.jpg"
                  modalEtiket1={"Bahçe Şöminesi"}
                  modalEtiket2={"Ateş çukuru"}
                  modalUrunOzellikleri="Ateş çukuru veya bahçe şöminesi olarak da kullanılan modeller arasındadır. Evinizin bahçesinde veya açık hava alanda kullanıma uygundur. Ürünlerimizin ölçü ve ebatlarında tasarıma göre değişkenlik göstermektedir."
                  modalMalzeme="Malzeme : Sac - 3mm / 4mm / 5mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="Çap : 60cm"
                  modalOlcuTaban="Derinlik : 25cm "
                  modalOlcuYukseklik="Yükseklik : 40cm"
                />
                {/* Bahçe Şöminesi */}
                <Urun
                  productName="#BS-001"
                  category="Bahçe Şömine"
                  categoryType="ates-cukuru-bahce-somine"
                  link="#ates-cukuru-bahce-somine"
                  imgLink="img/urunler/ates-cukuru-bahce-somine/B-S-001.jpg"
                  modalImg2="img/urunler/ates-cukuru-bahce-somine/B-S-001-2.jpg"
                  modalImg3="img/urunler/ates-cukuru-bahce-somine/B-S-001-3.jpg"
                  modalImg4="img/urunler/ates-cukuru-bahce-somine/B-S-002.jpg"
                  modalEtiket1={"Bahçe Şöminesi"}
                  modalEtiket2={"Ateş çukuru"}
                  modalUrunOzellikleri="Ateş çukuru veya bahçe şöminesi olarak da kullanılan modeller arasındadır. Evinizin bahçesinde veya açık hava alanda kullanıma uygundur. Ürünlerimizin ölçü ve ebatlarında tasarıma göre değişkenlik göstermektedir."
                  modalMalzeme="Malzeme : Sac - 2mm / 3mm / 4mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="Çap : 74cm"
                  modalOlcuTaban="Derinlik : 17cm "
                  modalOlcuYukseklik="Yükseklik : 57cm"
                />
                <Urun
                  productName="#BS-005"
                  category="Bahçe Şömine"
                  categoryType="ates-cukuru-bahce-somine"
                  link="#ates-cukuru-bahce-somine"
                  imgLink="img/urunler/ates-cukuru-bahce-somine/B-S-005.jpg"
                  modalImg2="img/urunler/ates-cukuru-bahce-somine/B-S-005-2.jpg"
                  modalImg3="img/urunler/ates-cukuru-bahce-somine/B-S-005-3.jpg"
                  modalImg4="img/urunler/ates-cukuru-bahce-somine/B-S-005-6.jpg"
                  modalEtiket1={"Bahçe Şöminesi"}
                  modalEtiket2={"Ateş çukuru"}
                  modalUrunOzellikleri="Ateş çukuru veya bahçe şöminesi olarak da kullanılan modeller arasındadır. Evinizin bahçesinde veya açık hava alanda kullanıma uygundur. Ürünlerimizin ölçü ve ebatlarında tasarıma göre değişkenlik göstermektedir."
                  modalMalzeme="Malzeme : Sac - 2mm / 3mm / 4mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="Çap : 75cm"
                  modalOlcuTaban="Derinlik : 20cm "
                  modalOlcuYukseklik="Yükseklik : 44cm"
                />
                <Urun
                  productName="#BS-006"
                  category="Bahçe Şömine"
                  categoryType="ates-cukuru-bahce-somine"
                  link="#ates-cukuru-bahce-somine"
                  imgLink="img/urunler/ates-cukuru-bahce-somine/B-S-006.jpg"
                  modalImg2="img/urunler/ates-cukuru-bahce-somine/B-S-006-2.jpg"
                  modalImg3="img/urunler/ates-cukuru-bahce-somine/B-S-006-3.jpg"
                  modalImg4="img/urunler/ates-cukuru-bahce-somine/B-S-006-4.jpg"
                  modalEtiket1={"Bahçe Şöminesi"}
                  modalEtiket2={"Ateş çukuru"}
                  modalUrunOzellikleri="Ateş kasesi, Ateş çukuru veya bahçe şöminesi olarak da kullanılan modeller arasındadır. Evinizin bahçesinde veya açık hava alanda kullanıma uygundur. Ürünlerimizin ölçü ve ebatlarında tasarıma göre değişkenlik göstermektedir.
                  Çağdaş bir tasarıma sahip  ateş kasesi , yıl boyunca bahçede birçok atmosferik akşam için idealdir. Ağır, yüksek kaliteli ateş kasesi, ısıya dayanıklı siyah toz boya ile kaplanmıştır ve herhangi bir bahçe veya terasa ilavedir. İki milimetrelik çelik kalınlığı, Kotka'nın çok dayanıklı olmasını sağlarken, 80 santimetrelik geniş çapı ile her bahçe veya terasta şık bir ısı kaynağı oluşturur. Bu ateş kabı, dökme demir plancha ızgara ızgarası ile de mevcuttur ve ateş kabının ince yüksekliği, salon takımı ile kullanım için idealdir. Ateş kasesi, bahçeye endüstriyel bir görünüm katar ve cömert boyutu, büyük kütükleri veya bahçe atığını yakmanıza olanak tanır. Bu ağır ateş kasesi montaj gerektirmez ve bir bütün olarak tedarik edilir."
                  modalMalzeme="Malzeme : Sac - 2mm / 3mm / 4mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="Boyutlar : 80cm X 80cm"
                  modalOlcuTaban="Derinlik : 17cm "
                  modalOlcuYukseklik="Yükseklik : 43cm"
                />
                <Urun
                  productName="#BS-007"
                  category="Bahçe Şömine"
                  categoryType="ates-cukuru-bahce-somine"
                  link="#ates-cukuru-bahce-somine"
                  imgLink="img/urunler/ates-cukuru-bahce-somine/B-S-007.jpg"
                  modalImg2="img/urunler/ates-cukuru-bahce-somine/B-S-007-2.jpg"
                  modalImg3="img/urunler/ates-cukuru-bahce-somine/B-S-007-3.jpg"
                  modalImg4="img/urunler/ates-cukuru-bahce-somine/B-S-007-4.jpg"
                  modalEtiket1={"Bahçe Şöminesi"}
                  modalEtiket2={"Ateş çukuru"}
                  modalUrunOzellikleri="Ateş çukuru veya bahçe şöminesi olarak da kullanılan modeller arasındadır. Evinizin bahçesinde veya açık hava alanda kullanıma uygundur. Ürünlerimizin ölçü ve ebatlarında tasarıma göre değişkenlik göstermektedir."
                  modalMalzeme="Malzeme : Sac - 2mm / 3mm / 4mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="Boyutlar : 80cm X 80cm"
                  modalOlcuTaban="Derinlik : 20cm "
                  modalOlcuYukseklik="Yükseklik : 44cm"
                />
                {/* Ateş Bacası */}
                <Urun
                  productName="#AB-001/002"
                  category="Ateş Bacası"
                  categoryType="ates-bacasi"
                  link="#ates-bacasi"
                  imgLink="img/urunler/ates-bacasi/A-B-001.jpg"
                  modalImg2="img/urunler/ates-bacasi/A-B-001-2.jpg"
                  modalImg3="img/urunler/ates-bacasi/A-B-002.jpg"
                  modalImg4="img/urunler/ates-bacasi/A-B-002-2.jpg"
                  modalEtiket1={"Ateş Bacası"}
                  modalUrunOzellikleri="Ateş bacası görselliğe önem verilmiş bir tasarımdır. Akşamları bahçenizde  keyfini süreceğiniz içinizi ısıcatacak şık bir tasarımla karşınızda #AB-001-002 modellerimiz."
                  modalMalzeme="Malzeme : Sac - 1mm / 1,5mm / 2mm"
                  modalAğırlık="Ağırlık : 18 kg"
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="Boyutlar : 60cm X 57cm"
                  modalOlcuTaban="Derinlik : - "
                  modalOlcuYukseklik="Yükseklik : 128cm"
                />
                <Urun
                  productName="#AB-003/005"
                  category="Ateş Bacası"
                  categoryType="ates-bacasi"
                  link="#ates-bacasi"
                  imgLink="img/urunler/ates-bacasi/A-B-003.jpg"
                  modalImg2="img/urunler/ates-bacasi/A-B-004.jpg"
                  modalImg3="img/urunler/ates-bacasi/A-B-005.jpg"
                  modalImg4="img/urunler/ates-bacasi/A-B-005-2.jpg"
                  modalEtiket1={"Ateş Bacası"}
                  modalUrunOzellikleri="Ateş bacası görselliğe önem verilmiş bir tasarımdır. Akşamları bahçenizde  keyfini süreceğiniz içinizi ısıcatacak corten(eskitme) sac tasarım modeli."
                  modalMalzeme="Malzeme : CORTEN Sac - 1mm / 1,5mm / 2mm"
                  modalAğırlık="Ağırlık : 18 kg"
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="Boyutlar : 60cm X 57cm"
                  modalOlcuTaban="Derinlik : - "
                  modalOlcuYukseklik="Yükseklik : 128cm"
                />
                <Urun
                  productName="#AB-006/007/008"
                  category="Ateş Bacası"
                  categoryType="ates-bacasi"
                  link="#ates-bacasi"
                  imgLink="img/urunler/ates-bacasi/A-B-006.jpg"
                  modalImg2="img/urunler/ates-bacasi/A-B-006-2.jpg"
                  modalImg3="img/urunler/ates-bacasi/A-B-007.jpg"
                  modalImg4="img/urunler/ates-bacasi/A-B-008.jpg"
                  modalEtiket1={"Ateş Bacası"}
                  modalUrunOzellikleri="Ateş bacası görselliğe önem verilmiş bir tasarımdır. Şık ve modern görünüm, ahşap için ayrı bir saklama alanı içerir ve ateş yakabileceğiniz orta bölüm vardır. Şömineyi kullanışlı ekstra saklama bölmesiyle kolayca doldurun ve o güzel yaz akşamında sıcaklığın uzun süre kalmasını sağlayın."
                  modalMalzeme="Malzeme : Sac - 1mm / 1,5mm / 2mm"
                  modalAğırlık="Ağırlık : 18 kg"
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="Boyutlar : 36cm X 36cm"
                  modalOlcuTaban="Derinlik : - "
                  modalOlcuYukseklik="Yükseklik : 150cm"
                />
                <Urun
                  productName="#AB-009"
                  category="Ateş Bacası"
                  categoryType="ates-bacasi"
                  link="#ates-bacasi"
                  imgLink="img/urunler/ates-bacasi/A-B-009.jpg"
                  modalImg2="img/urunler/ates-bacasi/A-B-009-2.jpg"
                  modalImg3="img/urunler/ates-bacasi/A-B-009-4.jpg"
                  modalImg4="img/urunler/ates-bacasi/A-B-009-3.jpg"
                  modalEtiket1={"Ateş Bacası"}
                  modalUrunOzellikleri="Ateş bacası görselliğe önem verilmiş bir tasarımdır. Bir birinden farklı modeller bulunmaktadır. Ateş yakılan bölgenin daha geniş olmasını sağladık."
                  modalMalzeme="Malzeme : CORTEN Sac - 1mm / 1,5mm / 2mm"
                  modalAğırlık="Ağırlık : 18 kg"
                  modalRenk="Renk : Siyah ( 850 °C kadar dayanıklı yanmaz boya ile yapılmıştır. )"
                  modalKullanimYeri1="Ev Bahçesi"
                  modalKullanimYeri2="Ofis Bahçesi , Açık Alan"
                  modalOlcuCap="Boyutlar : 45cm X 45cm"
                  modalOlcuTaban="Derinlik : - "
                  modalOlcuYukseklik="Yükseklik : 120cm"
                />
                {/* Corten Heykel Yapı */}
                <Urun
                  productName="#CHY-001"
                  category="Corten Heykel Yapı"
                  categoryType="corten-heykel-yapi"
                  link=" #corten-heykel-yapi"
                  imgLink="img/urunler/corten-heykel-yapi/C-H-Y-001.jpg"
                  modalImg2="img/urunler/corten-heykel-yapi/C-H-Y-001-2.jpg"
                  modalImg3="img/urunler/corten-heykel-yapi/C-H-Y-001-3.jpg"
                  modalImg4="img/urunler/corten-heykel-yapi/C-H-Y-001-4.jpg"
                  modalEtiket1={"Corten Heykel"}
                  modalEtiket2={"Corten Yapı"}
                  modalUrunOzellikleri="Corten sac üzerine tasarımlar gerçekleştirerek sanatsal yapılar ortaya çıkartmaya çalışyoruz. Üretilen ürünlerin özel yapı ve talep edilen tasarımlara göre üretimi gerçekleştirilmektedir. Ürünlerin ebatları,ağırlıklıklar tasarımlarımıza veya taleplere göre değişkenlik göstermektedir. Örnek ürünlerimizden teklif alabilirsiniz. Özel tasarım taleplerinizi de bize iletebilirsiniz. Bahçenize, Ofis girişine veya Evinize göre ister özel tasarımlar ister heykel yapıların tasarımları ve üretimleri tarafımızdan gerçekleştirilmektedir."
                  modalMalzeme="Malzeme : Corten Sac"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Paslı, Paslandırılmış(Eskitme)"
                  modalKullanimYeri1="Bahçe , Ev"
                  modalKullanimYeri2="Ofis  , Açık Alan"
                  modalOlcuCap="Değişkenlik göstermektedir.."
                  modalOlcuTaban="Derinlik : - "
                  modalOlcuYukseklik="Yükseklik : - "
                />
                <Urun
                  productName="#CHY-002"
                  category="Corten Heykel Yapı"
                  categoryType="corten-heykel-yapi"
                  link=" #corten-heykel-yapi"
                  imgLink="img/urunler/corten-heykel-yapi/C-H-Y-002.jpg"
                  modalImg2="img/urunler/corten-heykel-yapi/C-H-Y-002-2.jpg"
                  modalImg3="img/urunler/corten-heykel-yapi/C-H-Y-002-3.jpg"
                  modalImg4="img/urunler/corten-heykel-yapi/C-H-Y-002-4.jpg"
                  modalEtiket1={"Corten Heykel"}
                  modalEtiket2={"Corten Yapı"}
                  modalUrunOzellikleri="Corten sac üzerine tasarımlar gerçekleştirerek sanatsal yapılar ortaya çıkartmaya çalışyoruz. Üretilen ürünlerin özel yapı ve talep edilen tasarımlara göre üretimi gerçekleştirilmektedir. Ürünlerin ebatları,ağırlıklıklar tasarımlarımıza veya taleplere göre değişkenlik göstermektedir. Örnek ürünlerimizden teklif alabilirsiniz. Özel tasarım taleplerinizi de bize iletebilirsiniz. Bahçenize, Ofis girişine veya Evinize göre ister özel tasarımlar ister heykel yapıların tasarımları ve üretimleri tarafımızdan gerçekleştirilmektedir."
                  modalMalzeme="Malzeme : Corten Sac"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Paslı, Paslandırılmış(Eskitme)"
                  modalKullanimYeri1="Bahçe , Ev"
                  modalKullanimYeri2="Ofis  , Açık Alan"
                  modalOlcuCap="Değişkenlik göstermektedir.."
                  modalOlcuTaban="Derinlik : - "
                  modalOlcuYukseklik="Yükseklik : - "
                />
                <Urun
                  productName="#CHY-003/004/005/006"
                  category="Corten Heykel Yapı"
                  categoryType="corten-heykel-yapi"
                  link=" #corten-heykel-yapi"
                  imgLink="img/urunler/corten-heykel-yapi/C-H-Y-003.jpg"
                  modalImg2="img/urunler/corten-heykel-yapi/C-H-Y-004.jpg"
                  modalImg3="img/urunler/corten-heykel-yapi/C-H-Y-005.jpg"
                  modalImg4="img/urunler/corten-heykel-yapi/C-H-Y-006.jpg"
                  modalEtiket1={"Corten Heykel"}
                  modalEtiket2={"Corten Yapı"}
                  modalUrunOzellikleri="Corten sac üzerine tasarımlar gerçekleştirerek sanatsal yapılar ortaya çıkartmaya çalışyoruz. Üretilen ürünlerin özel yapı ve talep edilen tasarımlara göre üretimi gerçekleştirilmektedir. Ürünlerin ebatları,ağırlıklıklar tasarımlarımıza veya taleplere göre değişkenlik göstermektedir. Örnek ürünlerimizden teklif alabilirsiniz. Özel tasarım taleplerinizi de bize iletebilirsiniz. Bahçenize, Ofis girişine veya Evinize göre ister özel tasarımlar ister heykel yapıların tasarımları ve üretimleri tarafımızdan gerçekleştirilmektedir."
                  modalMalzeme="Malzeme : Corten Sac"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Paslı, Paslandırılmış(Eskitme)"
                  modalKullanimYeri1="Bahçe , Ev"
                  modalKullanimYeri2="Ofis  , Açık Alan"
                  modalOlcuCap="Değişkenlik göstermektedir.."
                  modalOlcuTaban="Derinlik : - "
                  modalOlcuYukseklik="Yükseklik : - "
                />
                <Urun
                  productName="#CHY-007/008/009/0010"
                  category="Corten Heykel Yapı"
                  categoryType="corten-heykel-yapi"
                  link=" #corten-heykel-yapi"
                  imgLink="img/urunler/corten-heykel-yapi/C-H-Y-007.jpg"
                  modalImg2="img/urunler/corten-heykel-yapi/C-H-Y-008.jpg"
                  modalImg3="img/urunler/corten-heykel-yapi/C-H-Y-009.jpg"
                  modalImg4="img/urunler/corten-heykel-yapi/C-H-Y-0010.jpg"
                  modalEtiket1={"Corten Heykel"}
                  modalEtiket2={"Corten Yapı"}
                  modalUrunOzellikleri="Corten sac üzerine tasarımlar gerçekleştirerek sanatsal yapılar ortaya çıkartmaya çalışyoruz. Üretilen ürünlerin özel yapı ve talep edilen tasarımlara göre üretimi gerçekleştirilmektedir. Ürünlerin ebatları,ağırlıklıklar tasarımlarımıza veya taleplere göre değişkenlik göstermektedir. Örnek ürünlerimizden teklif alabilirsiniz. Özel tasarım taleplerinizi de bize iletebilirsiniz. Bahçenize, Ofis girişine veya Evinize göre ister özel tasarımlar ister heykel yapıların tasarımları ve üretimleri tarafımızdan gerçekleştirilmektedir."
                  modalMalzeme="Malzeme : Corten Sac"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Paslı, Paslandırılmış(Eskitme)"
                  modalKullanimYeri1="Bahçe , Ev"
                  modalKullanimYeri2="Ofis  , Açık Alan"
                  modalOlcuCap="Değişkenlik göstermektedir.."
                  modalOlcuTaban="Derinlik : - "
                  modalOlcuYukseklik="Yükseklik : - "
                />
                <Urun
                  productName="#CHY-0011/0012/0013/0014"
                  category="Corten Heykel Yapı"
                  categoryType="corten-heykel-yapi"
                  link=" #corten-heykel-yapi"
                  imgLink="img/urunler/corten-heykel-yapi/C-H-Y-0011.jpg"
                  modalImg2="img/urunler/corten-heykel-yapi/C-H-Y-0012.jpg"
                  modalImg3="img/urunler/corten-heykel-yapi/C-H-Y-0013.jpg"
                  modalImg4="img/urunler/corten-heykel-yapi/C-H-Y-0014.jpg"
                  modalEtiket1={"Corten Heykel"}
                  modalEtiket2={"Corten Yapı"}
                  modalUrunOzellikleri="Corten sac üzerine tasarımlar gerçekleştirerek sanatsal yapılar ortaya çıkartmaya çalışyoruz. Üretilen ürünlerin özel yapı ve talep edilen tasarımlara göre üretimi gerçekleştirilmektedir. Ürünlerin ebatları,ağırlıklıklar tasarımlarımıza veya taleplere göre değişkenlik göstermektedir. Örnek ürünlerimizden teklif alabilirsiniz. Özel tasarım taleplerinizi de bize iletebilirsiniz. Bahçenize, Ofis girişine veya Evinize göre ister özel tasarımlar ister heykel yapıların tasarımları ve üretimleri tarafımızdan gerçekleştirilmektedir."
                  modalMalzeme="Malzeme : Corten Sac"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Paslı, Paslandırılmış(Eskitme)"
                  modalKullanimYeri1="Bahçe , Ev"
                  modalKullanimYeri2="Ofis  , Açık Alan"
                  modalOlcuCap="Değişkenlik göstermektedir.."
                  modalOlcuTaban="Derinlik : - "
                  modalOlcuYukseklik="Yükseklik : - "
                />
                <Urun
                  productName="#CHY-0015/0016/0017/0018"
                  category="Corten Heykel Yapı"
                  categoryType="corten-heykel-yapi"
                  link=" #corten-heykel-yapi"
                  imgLink="img/urunler/corten-heykel-yapi/C-H-Y-0015.jpg"
                  modalImg2="img/urunler/corten-heykel-yapi/C-H-Y-0016.jpg"
                  modalImg3="img/urunler/corten-heykel-yapi/C-H-Y-0017.jpg"
                  modalImg4="img/urunler/corten-heykel-yapi/C-H-Y-0018.jpg"
                  modalEtiket1={"Corten Heykel"}
                  modalEtiket2={"Corten Yapı"}
                  modalUrunOzellikleri="Corten sac üzerine tasarımlar gerçekleştirerek sanatsal yapılar ortaya çıkartmaya çalışyoruz. Üretilen ürünlerin özel yapı ve talep edilen tasarımlara göre üretimi gerçekleştirilmektedir. Ürünlerin ebatları,ağırlıklıklar tasarımlarımıza veya taleplere göre değişkenlik göstermektedir. Örnek ürünlerimizden teklif alabilirsiniz. Özel tasarım taleplerinizi de bize iletebilirsiniz. Bahçenize, Ofis girişine veya Evinize göre ister özel tasarımlar ister heykel yapıların tasarımları ve üretimleri tarafımızdan gerçekleştirilmektedir."
                  modalMalzeme="Malzeme : Corten Sac"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Paslı, Paslandırılmış(Eskitme)"
                  modalKullanimYeri1="Bahçe , Ev"
                  modalKullanimYeri2="Ofis , Açık Alan"
                  modalOlcuCap="Değişkenlik göstermektedir.."
                  modalOlcuTaban="Derinlik : - "
                  modalOlcuYukseklik="Yükseklik : - "
                />
                {/* Duvar Aksesuarları */}
                <Urun
                  productName="#DA-001/002/003"
                  category="Metal Duvar Aksesuarı"
                  categoryType="metal-duvar-aksesuar"
                  link="#metal-duvar-aksesuar"
                  imgLink="img/urunler/duvar-aksesuar/D-A-001.jpg"
                  modalImg2="img/urunler/duvar-aksesuar/D-A-001-2.jpg"
                  modalImg3="img/urunler/duvar-aksesuar/D-A-002.jpg"
                  modalImg4="img/urunler/duvar-aksesuar/D-A-003.jpg"
                  modalEtiket1={"Duvar Dekorasyon, Metal Dekorasyon"}
                  modalEtiket2={"Ev Aksesuarları, Metal Duvar Panosu"}
                  modalUrunOzellikleri="İç ve dış mekan kullanımına uygundur. Metal  Duvar Süsü Evinize - İş yerinize modern ve farklı bir hava katacak aynı zamanda güzel bir hediye alternatifi olabilecek bu üründür. 3 farklı boyutlara sahiptir. Montajı kolay çivi ile duvara asabilirsiniz."
                  modalMalzeme="Malzeme : Sac - 1,5mm / 2mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah /  Siyah Mat"
                  modalKullanimYeri1="Ev"
                  modalKullanimYeri2="Ofis"
                  modalOlcuCap="Boyutlar : 40cm X 51cm"
                  modalOlcuTaban="Boyutlar : 75cm X 90cm"
                  modalOlcuYukseklik="Boyutlar : 98cm X 122cm"
                />
                <Urun
                  productName="#DA-004/005/006/007"
                  category="Metal Duvar Aksesuarı"
                  categoryType="metal-duvar-aksesuar"
                  link="#metal-duvar-aksesuar"
                  imgLink="img/urunler/duvar-aksesuar/D-A-004.jpg"
                  modalImg2="img/urunler/duvar-aksesuar/D-A-005.jpg"
                  modalImg3="img/urunler/duvar-aksesuar/D-A-006.jpg"
                  modalImg4="img/urunler/duvar-aksesuar/D-A-007.jpg"
                  modalEtiket1={"Duvar Dekorasyon, Metal Dekorasyon"}
                  modalEtiket2={"Ev Aksesuarları, Metal Duvar Panosu"}
                  modalUrunOzellikleri="İç ve dış mekan kullanımına uygundur. Metal  Duvar Süsü Evinize - İş yerinize modern ve farklı bir hava katacak aynı zamanda güzel bir hediye alternatifi olabilecek bu üründür. 3 farklı boyutlara sahiptir. Montajı kolay çivi ile duvara asabilirsiniz."
                  modalMalzeme="Malzeme : Sac - 1,5mm / 2mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah /  Siyah Mat"
                  modalKullanimYeri1="Ev"
                  modalKullanimYeri2="Ofis"
                  modalOlcuCap="Boyutlar : 40cm X 51cm"
                  modalOlcuTaban="Boyutlar : 75cm X 90cm"
                  modalOlcuYukseklik="Boyutlar : 98cm X 122cm"
                />
                <Urun
                  productName="#DA-008/009/0010/0011"
                  category="Metal Duvar Aksesuarı"
                  categoryType="metal-duvar-aksesuar"
                  link="#metal-duvar-aksesuar"
                  imgLink="img/urunler/duvar-aksesuar/D-A-008.jpg"
                  modalImg2="img/urunler/duvar-aksesuar/D-A-009.jpg"
                  modalImg3="img/urunler/duvar-aksesuar/D-A-0010.jpg"
                  modalImg4="img/urunler/duvar-aksesuar/D-A-0011.jpg"
                  modalEtiket1={"Duvar Dekorasyon, Metal Dekorasyon"}
                  modalEtiket2={"Ev Aksesuarları, Metal Duvar Panosu"}
                  modalUrunOzellikleri="İç ve dış mekan kullanımına uygundur. Metal  Duvar Süsü Evinize - İş yerinize modern ve farklı bir hava katacak aynı zamanda güzel bir hediye alternatifi olabilecek bu üründür. 3 farklı boyutlara sahiptir. Montajı kolay çivi ile duvara asabilirsiniz."
                  modalMalzeme="Malzeme : Sac - 1,5mm / 2mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah /  Siyah Mat"
                  modalKullanimYeri1="Ev"
                  modalKullanimYeri2="Ofis"
                  modalOlcuCap="Boyutlar : 40cm X 51cm"
                  modalOlcuTaban="Boyutlar : 75cm X 90cm"
                  modalOlcuYukseklik="Boyutlar : 98cm X 122cm"
                />
                <Urun
                  productName="#DA-0012/0013/0014/0015"
                  category="Metal Duvar Aksesuarı"
                  categoryType="metal-duvar-aksesuar"
                  link="#metal-duvar-aksesuar"
                  imgLink="img/urunler/duvar-aksesuar/D-A-0012.jpg"
                  modalImg2="img/urunler/duvar-aksesuar/D-A-0013.jpg"
                  modalImg3="img/urunler/duvar-aksesuar/D-A-0014.jpg"
                  modalImg4="img/urunler/duvar-aksesuar/D-A-0015.jpg"
                  modalEtiket1={"Duvar Dekorasyon, Metal Dekorasyon"}
                  modalEtiket2={"Ev Aksesuarları, Metal Duvar Panosu"}
                  modalUrunOzellikleri="İç ve dış mekan kullanımına uygundur. Metal  Duvar Süsü Evinize - İş yerinize modern ve farklı bir hava katacak aynı zamanda güzel bir hediye alternatifi olabilecek bu üründür. 3 farklı boyutlara sahiptir. Montajı kolay çivi ile duvara asabilirsiniz."
                  modalMalzeme="Malzeme : Sac - 1,5mm / 2mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah /  Siyah Mat"
                  modalKullanimYeri1="Ev"
                  modalKullanimYeri2="Ofis"
                  modalOlcuCap="Boyutlar : 40cm X 51cm"
                  modalOlcuTaban="Boyutlar : 75cm X 90cm"
                  modalOlcuYukseklik="Boyutlar : 98cm X 122cm"
                />
                <Urun
                  productName="#DA-0016/0017/0018/0010"
                  category="Metal Duvar Aksesuarı"
                  categoryType="metal-duvar-aksesuar"
                  link="#metal-duvar-aksesuar"
                  imgLink="img/urunler/duvar-aksesuar/D-A-0016.jpg"
                  modalImg2="img/urunler/duvar-aksesuar/D-A-0017.jpg"
                  modalImg3="img/urunler/duvar-aksesuar/D-A-0018.jpg"
                  modalImg4="img/urunler/duvar-aksesuar/D-A-0019.jpg"
                  modalEtiket1={"Duvar Dekorasyon, Metal Dekorasyon"}
                  modalEtiket2={"Ev Aksesuarları, Metal Duvar Panosu"}
                  modalUrunOzellikleri="İç ve dış mekan kullanımına uygundur. Metal  Duvar Süsü Evinize - İş yerinize modern ve farklı bir hava katacak aynı zamanda güzel bir hediye alternatifi olabilecek bu üründür. 3 farklı boyutlara sahiptir. Montajı kolay çivi ile duvara asabilirsiniz."
                  modalMalzeme="Malzeme : Sac - 1,5mm / 2mm"
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : Siyah /  Siyah Mat"
                  modalKullanimYeri1="Ev"
                  modalKullanimYeri2="Ofis"
                  modalOlcuCap="Boyutlar : 40cm X 51cm"
                  modalOlcuTaban="Boyutlar : 75cm X 90cm"
                  modalOlcuYukseklik="Boyutlar : 98cm X 122cm"
                />
                {/* Masa & Tabure */}
                <Urun
                  productName="#MS-001"
                  category="Masa & Tabure"
                  categoryType="masa-tabure"
                  link=" #masa-tabure"
                  imgLink="img/urunler/masa-tabure/M-S-001.jpg"
                  modalImg2="img/urunler/masa-tabure/M-S-001-2.jpg"
                  modalImg3="img/urunler/masa-tabure/M-S-001-3.jpg"
                  modalImg4="img/urunler/masa-tabure/M-S-001-4.jpg"
                  modalEtiket1={"Masa & Tabure"}
                  modalEtiket2={"Demontable Masa & Tabure"}
                  modalUrunOzellikleri="Evinizde, ofisinizde veya açık alan bir yerde kolayça kurulumu sayesinde rahat edebileceğiniz masa ve tabure modelimiz. Demontable özelliği ile ister aracınıza yükleyin isterseniz  bir yerden bir yere elinizle taşıyın. Kalitesi ile uzun yıllar boyunca sorunsuz kullanabileceğiniz bir üründür."
                  modalMalzeme="Malzeme : - "
                  modalAğırlık="Ağırlık : Değişkenlik göstermektedir."
                  modalRenk="Renk : - "
                  modalKullanimYeri1="Ev "
                  modalKullanimYeri2="Ofis  , Açık Alan"
                  modalOlcuCap="Masa : 60cm X 120cm / 60cm X 180cm"
                  modalOlcuTaban="Tabure : 30cm X 120cm / 30cm X 180cm"
                  modalOlcuYukseklik="Yükseklik : - "
                />
                {/* Mumluk */}
                <Urun
                  productName="#M-001/002/003/004"
                  category="Mumluk"
                  categoryType="mumluk"
                  link=" #mumluk"
                  imgLink="img/urunler/mumluk/M-001.jpg"
                  modalImg2="img/urunler/mumluk/M-002.jpg"
                  modalImg3="img/urunler/mumluk/M-003.jpg"
                  modalImg4="img/urunler/mumluk/M-004.jpg"
                  modalEtiket1={"Ev Dekorasyon"}
                  modalEtiket2={"Ev Aksesuarları"}
                  modalUrunOzellikleri="Mumluklarımız 4 farklı modeldedir. Ürünlerimiz test ve üretim aşamasındadır."
                  modalMalzeme="Malzeme : - "
                  modalAğırlık="Ağırlık : - "
                  modalRenk="Renk :  - "
                  modalKullanimYeri1="Ev"
                  modalKullanimYeri2="Ofis"
                  modalOlcuCap="Boyutlar : - "
                  modalOlcuTaban="Derinlik : - "
                  modalOlcuYukseklik="Yükseklik : - "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Urunler;
