import React from "react";

function Counter() {
  return (
    <div className="container">
      <hr className="tall" />
      <div className="row counters">
        <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
          <div
            className="counter counter-primary appear-animation"
            data-appear-animation="bounceIn"
            data-appear-animation-delay="300"
          >
            <i className="fas fa-user"></i>
            <strong data-to="1500" data-append="+">
              0
            </strong>
            <label>Müşteri</label>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
          <div
            className="counter counter-primary appear-animation"
            data-appear-animation="bounceIn"
            data-appear-animation-delay="600"
          >
            <i className="fas fa-users"></i>
            <strong data-to="10">0</strong>
            <label>Yıllık Tecrübe</label>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 mb-4 mb-sm-0">
          <div
            className="counter counter-primary appear-animation"
            data-appear-animation="bounceIn"
            data-appear-animation-delay="900"
          >
            <i className="fas fa-window-restore"></i>
            <strong data-to="352">0</strong>
            <label>Proje</label>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div
            className="counter counter-primary appear-animation"
            data-appear-animation="bounceIn"
            data-appear-animation-delay="1200"
          >
            <i className="fas fa-cart-plus"></i>
            <strong data-to="30" data-append="+">
              0
            </strong>
            <label>Ürün</label>
          </div>
        </div>
      </div>
      {/* Muşteri Bilgisi */}
      <div className="row text-center pt-4">
        <div className="col">
          <h2 className="mb-2 word-rotator-title">
            BAŞARI VE KALİTE BİZİ SEÇEN{" "}
            <strong>
              <span
                className="word-rotator"
                data-plugin-options="{'delay': 3500, 'animDelay': 400}"
              >
                <span className="word-rotator-items">
                  <span> ÇALIŞANLAR </span>
                  <span> MÜŞTERİLER </span>
                </span>
              </span>
            </strong>{" "}
            SAYESİNDEDİR.
          </h2>
          <h4 className="heading-primary lead tall">
            1500'den fazla müşterilerimize hizmet verdik. Bizi tercih ettiğiniz
            için teşekkür ederiz.
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Counter;
