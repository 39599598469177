import React from "react";
import Alan from "./alan/Alan";

function Hizmetler() {
  return (
    <>
      {/* Sidebar */}
      <div role="main" className="main">
        <div className="container">
          <div className="row pt-2">
            {/* Sidebar */}
            <div className="col-lg-3">
              <aside
                className="sidebar"
                id="sidebar"
                data-plugin-sticky
                data-plugin-options="{'minWidth': 991, 'containerSelector': '.container', 'padding': {'top': 110}}"
              >
                <ul className="nav nav-list flex-column mb-4 show-bg-active">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-hash
                      data-hash-offset="110"
                      href="#hizmetler"
                    >
                      Hizmetler
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-hash
                      data-hash-offset="110"
                      href="#celik-kontruksiyon-yapi"
                    >
                      Çelik Kontrüksiyon & Yapı
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-hash
                      data-hash-offset="110"
                      href="#celik-guclendirme"
                    >
                      Çelik Güçlendirme
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-hash
                      data-hash-offset="110"
                      href="#hafif-celik-yapi"
                    >
                      Hafif Çelik Yapı
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-hash
                      data-hash-offset="110"
                      href="#dekoratif-mimari-tasarim"
                    >
                      Dekoratif Mimari Tasarım
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-hash
                      data-hash-offset="110"
                      href="#corten-yapi-sac"
                    >
                      Corten Yapı & Sac
                    </a>
                  </li>
                </ul>
              </aside>
            </div>
            {/* Sayfa İçi */}
            <div className="col-lg-9">
              {/* Hizmetler */}
              <section id="hizmetler" className="mb-2">
                <h2 className="mb-5  text-color-dark">Hizmetler</h2>
                <div className="row align-items-start">
                  <Alan
                    ek={"appear-animation"}
                    animasyon={"fadeInUp"}
                    sure={"300"}
                    alan={"Ağır Çelik Konstrüksiyon & Yapı"}
                    resim={"img/hizmetler/agir-celik-konstruksiyon-yapi-2.png"}
                  />

                  <Alan
                    ek={"appear-animation"}
                    animasyon={"fadeInUp"}
                    sure={"600"}
                    alan={"Hafif Çelik Yapılar"}
                    resim={"img/hizmetler/hafif-celik-yapi.png"}
                  />

                  <Alan
                    ek={"appear-animation"}
                    animasyon={"fadeInUp"}
                    sure={"900"}
                    alan={"Çelik Güçlendirme"}
                    aciklama={"proje,imalat,montaj"}
                    resim={"img/hizmetler/celik-guclendirme.png"}
                  />
                </div>

                <div className="row align-items-center">
                  <Alan
                    ek={"appear-animation"}
                    animasyon={"fadeInUp"}
                    sure={"1200"}
                    alan={"Ferforje"}
                    resim={"img/hizmetler/ferforje.png"}
                  />

                  <Alan
                    ek={"appear-animation"}
                    animasyon={"fadeInUp"}
                    sure={"1500"}
                    alan={"Fuar Stantları"}
                    aciklama={"proje,imalat,montaj"}
                    resim={"img/hizmetler/stand-fuar-zemin.png"}
                  />

                  <Alan
                    ek={"appear-animation"}
                    animasyon={"fadeInUp"}
                    sure={"1800"}
                    alan={"Kış Bahçeleri"}
                    resim={"img/hizmetler/kis-bahcesi.png"}
                  />
                </div>

                <div className="row align-items-end">
                  <Alan
                    ek={"appear-animation"}
                    animasyon={"fadeInUp"}
                    sure={"2100"}
                    alan={"Dekoratif Mimari Tasarım"}
                    resim={"img/hizmetler/celik-mimari-tasarim.jpg"}
                  />

                  <Alan
                    ek={"appear-animation"}
                    animasyon={"fadeInUp"}
                    sure={"2400"}
                    alan={"Çelik Çatılar"}
                    resim={"img/hizmetler/celik-cati.png"}
                  />

                  <Alan
                    ek={"appear-animation"}
                    animasyon={"fadeInUp"}
                    sure={"2700"}
                    alan={"Archepon Mimari Tasarım"}
                    resim={"img/hizmetler/archepon.png"}
                  />
                </div>
              </section>

              <hr className="solid tall" />
              {/* Celik Konstruksiyon */}
              <section id="celik-kontruksiyon-yapi" className="mb-4">
                <h2 className="mb-5  text-color-dark">
                  Çelik Kontrüksiyon & Yapı
                </h2>
                <h4 className="mb-5 text-color-dark">
                  Çelik Konstrüksiyon Yapı Nedir?
                </h4>

                <p className="mb-5 text-color-dark">
                  Tüm taşıyıcı sistemlerin çelikten üretildiği, özellikle deprem
                  tehdidi taşıyan coğrafi bölgelerde tercih edilen, çoğunlukla
                  çelik konstrüksiyon fabrika binaları, çelik konstrüksiyon
                  hangar, çelik konstrüksiyon spor tesisleri, çelik
                  konstrüksiyon depo ve çelik konstrüksiyon atölyelerde
                  kullanılan, geniş açıklıklara ve yüksekliğe sahip çelik yapı
                  sistemidir. Ülkemizdeki kentleşmenin büyük çoğunluğu 1. ve 2.
                  derecede deprem bölgesinde yoğunlaşmıştır. Buna oranla çelik
                  konstrüksiyon yapı sistemlerine olan tercih geçmişte oldukça
                  azdı. Çelik yapılar diğer taşıyıcı sistemlere göre çok üstün
                  mekanik özelliklere , statik yeteneklere ve uygulama
                  kolaylığına sahiptir. Çelik konstrüksiyon, hızlı, güvenli ve
                  ekonomik olması ve belirli standartlara sahip olması nedeniyle
                  hızla yaygınlaşan çelik yapı sistemidir.
                </p>
                <img
                  className="wow img-fluid mb-5 appear-animation"
                  width="300"
                  height="211"
                  src="img/hizmetler/agir-celik-konstruksiyon-yapi.png"
                  alt="agir-celik-konstruksiyon-yapi"
                  data-appear-animation="bounceInLeft"
                  data-appear-animation-delay="0"
                  data-appear-animation-duration="1s"
                />

                <h4 className="mb-5 text-color-dark">
                  Çelik Konstrüksiyon Yapıların Türleri Nelerdir?
                </h4>
                <p className="mb-5 text-color-dark">
                  Çelik konstrüksiyon yapılar, ağır çelik ve hafif çelik
                  konstrüksiyon yapılar olarak ikiye ayrılmaktadır. Büyük
                  açıklıklı ve kreyn’li fabrika binalarını ağır çelik yapılara,
                  prefabrik atölye ve depo yapılarını da hafif çelik
                  konstrüksiyon yapılara örnek verebiliriz. Çelik konstrüksiyon
                  yapılar yalıtımlı ve yalıtımsız olarak da ikiye ayrılmaktadır.
                  Yalıtımlı binaların duvar ve çatılarında kullanılan poliüretan
                  paneller veya taş yünü paneller sayesinde ısı ve ses yalıtımı
                  sağlanmaktadır. Yalıtımsız çelik binaların tercih edilme
                  sebebi ise ekonomik olmasıdır.
                </p>
                <h4 className="mb-5 text-color-dark">
                  Çelik Konstrüksiyon Yapı Nerelerde Kullanılmaktadır?
                </h4>
                <p className="mb-5 text-color-dark">
                  Çelik konstrüksiyon yapılar çoğunlukla çelik fabrika binaları,
                  çelik sanayi yapıları, uçak hangarları, spor tesisleri, çelik
                  konstrüksiyon hangarlar, çelik konstrüksiyon atölyeler, çelik
                  konstrüksiyon depolar gibidir. Çelik Konstrüksiyon Yapı
                  Avantajları Nelerdir? Hafif olması nedeniyle daha az deprem
                  yüklerine maruz kalır, sağlamdır, imalat ve montajda işçilik
                  ve zamandan tasarruf sağlar, farklı konstrüksiyon sistemleri
                  uygulanabilir, hızlıdır, defalarca sökülüp tekrar monte
                  edilebilir, uzun ömürlüdür, çelik %100 geri dönüştürülebilir
                  ve bu işlem kaç kez yapılırsa yapılsın, özelliğini kaybetmez,
                  çevreye zarar vermez.
                </p>
                <img
                  className="wow img-fluid mb-5 appear-animation"
                  width="300"
                  height="211"
                  src="img/hizmetler/agir-celik-konstruksiyon-yapi-2.png"
                  alt="agir-celik-konstruksiyon-yapi"
                  data-appear-animation="bounceInRight"
                  data-appear-animation-delay="0"
                  data-appear-animation-duration="1s"
                />
              </section>

              <hr className="solid tall" />
              {/* Celik Guclendirme */}
              <section id="celik-guclendirme" className="mb-4">
                <h2 className="mb-5 text-color-dark">Çelik Güçlendirme</h2>
                <p className="mb-4">
                  Çelik Güçlendirme Sistemleri eski fakat maddi veya manevi
                  öneme haiz yapıların onarım ve güçlendirilmesinde kullanılır.
                  Özellikle betonarme yapıların karkas sistemlerinin onarım veya
                  desteklenmesi için çok uygun bir yoldur.
                </p>
                <img
                  className="wow float-right img-fluid mb-5 mt-3 appear-animation"
                  width="250"
                  height="200"
                  src="img/hizmetler/celik-guclendirme-2.png"
                  alt="celik-guclendirme"
                  data-appear-animation="bounceInLeft"
                  data-appear-animation-delay="0"
                  data-appear-animation-duration="1s"
                />
                <h4 className="mb-4 text-color-dark">GÜÇLENDİRME İHTİYACI</h4>

                <ul>
                  <li>Kullanımda değişiklik (konut – okul)</li>
                  <li>Mevcut yönetmelik koşulları sağlamamakta</li>
                  <li>Beton dayanımında kuşku</li>
                  <li>Donatıdan kuşku</li>
                  <li>n Proje hatası</li>
                  <li>n Projeye uymama</li>
                  <li>Yeni bir kat eklenmesi veya çok sayıda taşıyıcı</li>
                  <li>Olmayan bölme duvar yapılması</li>
                  <li>Yetersiz yanal rijitlik</li>
                  <li>Hasar</li>
                </ul>

                <img
                  className="wow float-right img-fluid mb-5 mt-3 appear-animation"
                  width="250"
                  height="200"
                  src="img/hizmetler/celik-guclendirme-3.png"
                  alt="celik-guclendirme"
                  data-appear-animation="bounceInLeft"
                  data-appear-animation-delay="0"
                  data-appear-animation-duration="2s"
                />
                <h4 className="mb-4 text-color-dark">
                  {" "}
                  GÜÇLENDİRME GENEL KULLANIM ALANLARI
                </h4>

                <ul>
                  <li>Betonarme Yapılar</li>
                  <li>Çelik Yapılar</li>
                  <li>Ahşap Yapılar</li>
                </ul>
              </section>

              <hr className="solid tall" />
              {/* Hafif Celik Yapi */}
              <section id="hafif-celik-yapi" className="mb-4">
                <h2 className="mb-5 text-color-dark">Hafif Çelik Yapı</h2>
                <p className="mb-5 text-color-dark">
                  Hafif çelik konstrüksiyon teknolojisinin mimari açıdan son
                  yıllarda yapılan binalarda karşılanması gereken üç kriteri;
                  sadelik, estetik ve narinlik içerdiği görülmektedir. Hafif
                  çelik, konstrüksiyonda kullanan elemanların ve bunlarla
                  birlikte bileşenlerin de inceltilmeleriyle bu üç kriterin elde
                  edilmesini sağlamaktadır. Bina konstrüksiyon sisteminde
                  incelme ve hafiflik, kullanılan malzemen miktarı ya da
                  hacminde azalma olması ile elde edilmektedir. Soğuk bükme
                  çelikte, çeliğin malzeme olarak kullanımı azaltılmaktadır.
                  Mimari ve çevresel gerekliliklerin yanında zorlu ekonomik
                  koşullar da, mühendislerin hafif çelik kullanmalarına neden
                  olmaktadır. Hafif çelik teknolojisi, malzemenin sahip
                  olabileceği en az ağrılıkla, istenen tüm gerekli özellikleri
                  sağlayabilmesini gerektirmektedir.
                </p>
                <img
                  className="wow img-fluid mb-5 mt-3 mr-5 appear-animation"
                  width="300"
                  height="200"
                  src="img/hizmetler/hafif-celik-yapi.png"
                  alt="hafif-celik-yapi"
                  data-appear-animation="flipInX"
                  data-appear-animation-delay="0"
                  data-appear-animation-duration="2s"
                />
                <img
                  className="wow img-fluid mb-5 mt-3 appear-animation"
                  width="300"
                  height="200"
                  src="img/hizmetler/hafif-celik-yapi-2.png"
                  alt="hafif-celik-yapi"
                  data-appear-animation="flipInX"
                  data-appear-animation-delay="0"
                  data-appear-animation-duration="2s"
                />
                <h4 className="mb-5 text-color-dark">
                  Yapı Çeliği ve Hafif Çelik Arasındaki Farklar
                </h4>
                <p className="mb-5 text-color-dark">
                  Kesit yapıları farklıdır. Şekil ve büyüklük olarak
                  farklıdırlar. Üretim teknolojileri faklıdır. Hafif çelik soğuk
                  haddelenmiş olarak norma loda koşullarında üretilmekteyken,
                  yapısal çelik ise sıcak haddelenmiş olarak üretilmektedir.
                  Soğuk bükme işlemi sırasında ortaya çıkan artık gerilmeler
                  yapısal çelikte yoktur. Hafif çelik elemanların köşeleri
                  yuvarlatılmıştır.
                </p>
                <img
                  className="wow img-fluid mb-5 mt-3 appear-animation"
                  width="300"
                  height="200"
                  src="img/hizmetler/hafif-celik-yapi-3.png"
                  alt="hafif-celik-yapi"
                  data-appear-animation="flipInX"
                  data-appear-animation-delay="0"
                  data-appear-animation-duration="2s"
                />
              </section>

              <hr className="solid tall" />
              {/* Celik Mimari Tasarım & dekorasyon */}
              <section id="dekoratif-mimari-tasarim" className="mb-4">
                <h2 className="mb-0 text-color-dark">
                  Dekoratif Mimari Tasarım
                </h2>
                <p className=" mb-0 mt-5 ">
                  Mimari tasarım, günlük hayatta karşımıza çıkan yapıların,
                  mekanların, eşyaların, hatta bazen yolların ve yollardaki
                  bitkilerin ağaçların bile belli bir kurala veya güzellik,
                  işlevsellik algısına dayanılarak tasarlanması durumudur. ...
                  Sadece belirli bir bina tasarım sürecine girer ve sonuç olarak
                  inşa edilir.
                </p>
                <img
                  className="wow img-fluid mb-5 mt-3 mr-3 appear-animation"
                  width="300"
                  height="200"
                  src="img/hizmetler/celik-mimari-tasarim.jpg"
                  alt="celik-mimari-tasarim"
                  data-appear-animation="rotateInDownLeft"
                  data-appear-animation-delay="0"
                  data-appear-animation-duration="1.5s"
                />
                <img
                  className="wow img-fluid mb-5 mt-3 appear-animation"
                  width="300"
                  height="200"
                  src="img/hizmetler/celik-mimari-tasarim-2.jpg"
                  alt="celik-mimari-tasarim"
                  data-appear-animation="rotateInDownRight"
                  data-appear-animation-delay="0"
                  data-appear-animation-duration="1.5s"
                />
              </section>
              <hr className="solid tall" />
              {/* Corten Nedir */}
              <section id="corten-yapi-sac" className="mb-4">
                <h2 className="mb-0 text-color-dark">
                  Corten Yapı & Corten Sac Tasarımı
                </h2>
                <p className=" mb-0 mt-5 ">
                  Belirli bir sınıf karbon çeliktir bahsi geçen cor-ten çelik.
                  Bu çelik aslında hava koşullarına yüksek mukavemet gösterir.
                  Kısaca ifade edilecek olursa “paslı” ya da “paslandırılmış”
                  çelik de denebilir. Bu çeliklerin en yaygın olarak bilinen
                  COR-TEN ® Amerikan USX Corporation tarafından geliştirilen,
                  ancak bazı alanlarda CORTEN çelik gibi tire olmadan bilinen
                  özel üretilmiş çelik metalidir.
                </p>
                <p>
                  COR-TEN (CORTEN - weathering steel - eskitme çelik); sanayi
                  yapılarında, mimari ve estetik projelerde kullanılan korozyon
                  (pas)dirençli, bakım istemeyen uzun ömürlü özel çeliktir.
                  COR-TEN Çelikleri, atmosferdeki yumuşak çelik ve düşük
                  karbonlu çeliklere göre yüksek korozyon direnci gösteren
                  çeliklerdir.
                </p>
                <img
                  className="wow img-fluid mb-5 mt-3 mr-3 appear-animation"
                  width="300"
                  height="200"
                  src="img/hizmetler/corten-yapi.jpg"
                  alt="Corten Yapı"
                  data-appear-animation="rotateInDownLeft"
                  data-appear-animation-delay="0"
                  data-appear-animation-duration="1.5s"
                />
                <img
                  className="wow img-fluid mb-5 mt-3 appear-animation"
                  width="300"
                  height="200"
                  src="img/hizmetler/corten-yapi-2-troyamuzesi.jpg"
                  alt="Corten Yapı"
                  data-appear-animation="rotateInDownRight"
                  data-appear-animation-delay="0"
                  data-appear-animation-duration="1.5s"
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hizmetler;
