import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
function Projeler() {
  return (
    <div className="container">
      <hr className="tall" />

      <div className="row">
        <div className="col">
          <h2 className="font-weight-bold text-color-dark">Projeler</h2>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div
            className="owl-carousel owl-theme show-nav-title top-border"
            data-plugin-options="{'responsive': {'0': {'items': 1}, '479': {'items': 1}, '768': {'items': 1}, '979': {'items': 1}, '1199': {'items': 1}}, 'items': 1, 'margin': 10, 'loop': false, 'nav': true, 'dots': false}"
          >
            <div className="recent-posts">
              <article className="post">
                <div className="row">
                  <div className="col-sm-8 col-lg-5">
                    <div className="blog-post-image-wrapper">
                      <img
                        src="img/projeler/day-group-fulya-bina-main.jpg"
                        alt="Day Group"
                        className="wow img-fluid appear-animation mb-4"
                        data-appear-animation="fadeInLeftBig"
                        data-appear-animation-delay="0"
                        data-appear-animation-duration="1s"
                      />
                    </div>
                  </div>
                  <div
                    className="wow col-sm-12 col-lg-7 appear-animation"
                    data-appear-animation="fadeInRightBig"
                    data-appear-animation-delay="0"
                    data-appear-animation-duration="1s"
                  >
                    <h2> DAY GROUP </h2>
                    <p>
                      Fulya'da bulunan Day Group firmasına ait, tamamen kentsel
                      dönüşüm mantığı altında planlanmış. Proje kapsamında;
                      deprem...
                    </p>
                    <hr className="solid" />
                    <div className="post-infos d-flex">
                      <span className="info posted-by">
                        Proje Adı :
                        <span className="post-author font-weight-semibold text-color-dark">
                          {" "}
                          Fulya Binası
                        </span>
                      </span>

                      <span className="info posted-by ml-5">
                        Yer :
                        <span className="post-author font-weight-semibold custom-color-dark text-color-dark">
                          {" "}
                          Fulya / BEŞİKTAŞ
                        </span>
                      </span>
                    </div>
                    <a
                      className="btn btn-outline custom-border-width btn-secondary custom-border-radius font-weight-semibold text-uppercase mt-5"
                      href="/faaliyetler#projeler"
                      title="Daha Fazla"
                    >
                      Daha Fazla
                    </a>
                  </div>
                </div>
              </article>
            </div>

            <div>
              <div className="recent-posts">
                <article className="post">
                  <div className="row">
                    <div className="blog-post col">
                      <div className="row">
                        <div className="col-sm-8 col-lg-5">
                          <div className="blog-post-image-wrapper">
                            <img
                              src="img/projeler/galataport-saksilik-main.jpg"
                              alt="Galata Port Saksılık"
                              className="wow img-fluid appear-animation mb-4"
                              data-appear-animation="fadeInLeftBig"
                              data-appear-animation-delay="0"
                              data-appear-animation-duration="1s"
                            />
                          </div>
                        </div>
                        <div
                          className="wow col-sm-12 col-lg-7 appear-animation"
                          data-appear-animation="fadeInRightBig"
                          data-appear-animation-delay="0"
                          data-appear-animation-duration="1s"
                        >
                          <h2> Işık Peyzaj </h2>
                          <p>
                            Galataport projesinde tasarlanan bütün deniz çevresi
                            boyunca dikilecek ağaçların taşıyıcı saksı
                            uygulaması yapılmaktadır. Taşıyıcı saksılar...
                          </p>
                          <hr className="solid" />
                          <div className="post-infos d-flex">
                            <span className="info posted-by">
                              Proje Adı :
                              <span className="post-author font-weight-semibold text-color-dark">
                                {" "}
                                Galataport Saksılık
                              </span>
                            </span>

                            <span className="info like ml-5">
                              Yer :
                              <span className="like-number font-weight-semibold text-color-dark custom-color-red">
                                {" "}
                                Tophane / KARAKÖY
                              </span>
                            </span>
                          </div>
                          <a
                            className="btn btn-outline custom-border-width btn-secondary custom-border-radius font-weight-semibold text-uppercase mt-5"
                            href="/faaliyetler#projeler"
                            title="Daha Fazla"
                          >
                            Daha Fazla
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div>
              <div className="recent-posts">
                <article className="post">
                  <div className="row">
                    <div className="blog-post col">
                      <div className="row">
                        <div className="col-sm-8 col-lg-5">
                          <div className="blog-post-image-wrapper">
                            <img
                              src="img/projeler/tarman-insaat-pangalti-main.jpg"
                              alt="Tarman İnşaat - Pangaltı"
                              className="wow img-fluid appear-animation mb-4"
                              data-appear-animation="fadeInLeftBig"
                              data-appear-animation-delay="0"
                              data-appear-animation-duration="1s"
                            />
                          </div>
                        </div>
                        <div
                          className="wow col-sm-12 col-lg-7 appear-animation"
                          data-appear-animation="fadeInRightBig"
                          data-appear-animation-delay="0"
                          data-appear-animation-duration="1s"
                        >
                          <h2> TARMAN İNŞAAT</h2>
                          <p>
                            İstanbul'un tarihi evlerinden Pangaltı'nda bulunan
                            resterasyon işlemi yapılacak olan binanın giriş
                            kapısı, balkon korkulukları ve çelik çatı...
                          </p>
                          <hr className="solid" />
                          <div className="post-infos d-flex">
                            <span className="info posted-by">
                              Proje Adı :
                              <span className="post-author font-weight-semibold text-color-dark">
                                {" "}
                                RESTERASYON
                              </span>
                            </span>

                            <span className="info like ml-5">
                              Yer :
                              <span className="like-number font-weight-semibold  text-color-dark custom-color-red">
                                {" "}
                                Pangaltı / BEŞİKTAŞ
                              </span>
                            </span>
                          </div>
                          <a
                            className="btn btn-outline custom-border-width btn-secondary custom-border-radius font-weight-semibold text-uppercase mt-5"
                            href="/faaliyetler#projeler"
                            title="Daha Fazla"
                          >
                            Daha Fazla
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projeler;
